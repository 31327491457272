<template>
  <div class="accent-balance">
    <div class="main">
      <!-- 导航 -->
      <div class="balance-nav-div">
        <div
          class="nav-recharge-text balance-nav-text posi"
          :class="
            selectedNavType == navType.recharge
              ? 'balance-nav-text-selected'
              : ''
          "
          @click="navClick(1)"
        >
          充值信息
        </div>
        <div
          class="balance-nav-text posi"
          :class="
            selectedNavType == navType.balance
              ? 'balance-nav-text-selected'
              : ''
          "
          @click="navClick(2)"
        >
          余额明细
        </div>
      </div>
      <!-- 充值信息 -->
      <div class="recharge-data" v-if="selectedNavType == navType.recharge">
        <!-- 余额显示 -->
        <div class="balance-show-div">
          <div class="tip-title">您目前的账户余额：</div>
          <div class="balance-show">
            <div class="balance-text">{{ myBalance }}</div>
            <div class="balance-unit">英镑</div>
          </div>
        </div>
        <!-- 充值选项 -->
        <div class="recharge-show">
          <!-- 提示标语 -->
          <div class="tip-title">充值金额：</div>
          <!-- 金额选择 -->
          <div class="recharge-select-div">
            <div
              class="recharge-item posi"
              :class="
                selectedRechargeType == rechargeType.oneHundred
                  ? 'recharge-item-selected'
                  : ''
              "
              @click="rechargeItemClicked(100)"
            >
              100英镑
              <img
                v-if="selectedRechargeType == rechargeType.oneHundred"
                class="select-icon"
                src="../../assets/img/myAccount/Balance/icon_recharge_selected.png"
              />
            </div>
            <div
              class="recharge-item posi"
              :class="
                selectedRechargeType == rechargeType.twoHundred
                  ? 'recharge-item-selected'
                  : ''
              "
              @click="rechargeItemClicked(200)"
            >
              200英镑
              <img
                v-if="selectedRechargeType == rechargeType.twoHundred"
                class="select-icon"
                src="../../assets/img/myAccount/Balance/icon_recharge_selected.png"
              />
            </div>
            <div
              class="recharge-item posi"
              :class="
                selectedRechargeType == rechargeType.threeHundred
                  ? 'recharge-item-selected'
                  : ''
              "
              @click="rechargeItemClicked(300)"
            >
              300英镑
              <img
                v-if="selectedRechargeType == rechargeType.threeHundred"
                class="select-icon"
                src="../../assets/img/myAccount/Balance/icon_recharge_selected.png"
              />
            </div>
            <div
              class="recharge-item posi"
              v-if="selectedRechargeType != rechargeType.custom"
              @click="rechargeItemClicked(0)"
            >
              自定义
            </div>
            <div class="recharge-input" v-else>
              <el-input
                v-focus
                v-model="rechargeInputNum"
                placeholder="请输入金额"
                @input="limitInput($event)"
              ></el-input>
              <img
                class="select-icon"
                src="../../assets/img/myAccount/Balance/icon_recharge_selected.png"
              />
            </div>
          </div>
          <!-- 充值按钮 -->
          <div class="recharge-btn posi" @click="rechargeBtnClicked">
            去充值
          </div>
        </div>
      </div>
      <div class="balance-data" v-if="selectedNavType == navType.balance">
        <!-- 查询条件显示 -->
        <div class="search-condition-show">
          <!-- 快捷时间 -->
          <div class="radio-div">
            <el-radio-group
              v-model="balanceSearchCondition"
              @change="balanceSearchConditionChanged"
            >
              <el-radio :label="searchConditionype.all">全部</el-radio>
              <el-radio :label="searchConditionype.today">本日</el-radio>
              <el-radio :label="searchConditionype.week">近七日</el-radio>
              <el-radio :label="searchConditionype.month">近30日</el-radio>
              <el-radio :label="searchConditionype.year">近1年</el-radio>
            </el-radio-group>
          </div>
          <!-- 自定义选择时间 -->
          <div class="select-data-div">
            <el-date-picker
              popper-class="date-picker"
              v-model="balanceSearchCustomData"
              type="daterange"
              :editable="false"
              range-separator="至"
              start-placeholder="开始时间"
              end-placeholder="结束时间"
              value-format="yyyy-MM-dd"
              @change="balanceSearchCustomDataChanged"
            >
            </el-date-picker>
          </div>
        </div>
        <!-- 查询结果显示 -->
        <div class="search-result-show">
          <div class="table-div">
            <el-table
              :data="seachShowData"
              style="width: 100%"
              border
              empty-text="暂无数据"
              v-loading="loading"
            >
              <el-table-column
                prop="createTime"
                label="日期"
                width="182"
                :resizable="false"
              >
              </el-table-column>
              <el-table-column
                prop="amounts"
                label="金额(£)"
                width="87"
                :resizable="false"
              >
              </el-table-column>
              <el-table-column
                prop="surplus"
                label="余额(£)"
                width="87"
                :resizable="false"
              >
              </el-table-column>
              <el-table-column
                prop="operationTypeName"
                label="支付方式"
                width="142"
                :resizable="false"
              >
              </el-table-column>
              <el-table-column prop="note" label="消费备注" :resizable="false">
              </el-table-column>
            </el-table>
          </div>
          <div class="pagination-div">
            <el-pagination
              background
              layout="prev, pager, next"
              :hide-on-single-page="true"
              :total="pageCount"
              @current-change="changePage"
            >
            </el-pagination>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {
  createPayment,
  listUserAmountRecord,
  getUserAmount,
} from "@/api/user/user";
export default {
  name: "AccentBalance",
  data() {
    return {
      //导航类型
      navType: {
        recharge: 1,
        balance: 2,
      },
      //充值类型
      rechargeType: {
        oneHundred: 100,
        twoHundred: 200,
        threeHundred: 300,
        custom: 0,
      },
      //余额明细查询筛选条件类型
      searchConditionype: {
        all: 0,
        today: 1,
        week: 2,
        month: 3,
        year: 4,
        custom: 5,
      },
      //选择的导航类型
      selectedNavType: 1,
      //账户余额
      myBalance: 0,
      //充值输入的金额
      rechargeInputNum: "",
      //选择的充值选项卡
      selectedRechargeType: 100,
      //选择的余额明细查询条件
      balanceSearchCondition: 0,
      //自定义选择的时间
      balanceSearchCustomData: "",
      //明细搜索结果展示
      seachShowData: [
        {
          date: "2022-12-02  20:04:50",
          money: "-25.00",
          balance: "345.98",
          pay: "Account Balance",
          remark: "SD220094339GB SD220094339GB SD220094339GB",
        },
      ],
      //分页总条数
      pageCount: 0,
      // 当前页码
      pageNum: 1,
      // 表格loading
      loading: false,
    };
  },
  directives: {
    focus: {
      inserted: function (el) {
        el.querySelector("input").focus();
      },
    },
  },
  created() {
    this.getUserAmount();
    this.listUserAmountRecord();
  },
  methods: {
    //获取账户余额
    getUserAmount() {
      console.log("账户余额111");
      getUserAmount().then((res) => {
        if (res.data.code === 0) {
          this.myBalance = res.data.data.data.amount;
        }
        console.log(res, "账户余额");
      });
    },
    // 获取账户余额明细接口
    listUserAmountRecord() {
      let upData = {
        endTime: this.balanceSearchCustomData[1] || undefined,
        pageNum: this.pageNum,
        pageSize: "10",
        queryType: this.balanceSearchCondition,
        startTime: this.balanceSearchCustomData[0] || undefined,
      };
      this.loading = true;
      listUserAmountRecord(upData).then((res) => {
        if (res.data.code === 0) {
          this.seachShowData = res.data.data.list.list;
          this.pageCount = res.data.data.list.totalCount;
        } else {
          this.$message.error(res.data.message);
        }
        this.loading = false;
      });
    },
    limitInput(value) {
      this.rechargeInputNum =
        ("" + value) // 第一步：转成字符串
          .replace(/[^\d^\\.]+/g, "") // 第二步：把不是数字，不是小数点的过滤掉
          .replace(/^0+(\d)/, "$1") // 第三步：第一位0开头，0后面为数字，则过滤掉，取后面的数字
          .replace(/^\./, "0.") // 第四步：如果输入的第一位为小数点，则替换成 0. 实现自动补全
          .match(/^\d*(\.?\d{0,2})/g)[0] || ""; // 第五步：最终匹配得到结果 以数字开头，只有一个小数点，而且小数点后面只能有0到2位小数
    },
    // 导航按钮被点击
    navClick(arg) {
      this.selectedNavType = arg;
    },
    // 金额选择被点击
    rechargeItemClicked(arg) {
      this.selectedRechargeType = arg;
    },
    // 去充值按钮被点击
    rechargeBtnClicked() {
      let rechargeNum = 0;
      if (this.selectedRechargeType == 0) {
        rechargeNum = this.rechargeInputNum ? this.rechargeInputNum : 0;
      } else {
        rechargeNum = this.selectedRechargeType;
      }
      // console.log("href",window.location)
      let upData = {
        price: rechargeNum, // 充值金额
        // urlPath:'http://localhost:9527/#/MyAccount'
        urlPath: `${window.location.href}`,
      };
      createPayment(upData).then((res) => {
        if (res.data.code === 0) {
          let url = res.data.data.toPath;
          this.$store.state.payId = res.data.data.payId;
          window.location.href = url;
        } else {
          this.$message.error(res.data.message);
        }
      });
    },
    // 选择了查询条件按钮
    balanceSearchConditionChanged(arg) {
      console.log("选择了余额明细查询条件", arg);
      this.balanceSearchCustomData = "";
      this.listUserAmountRecord();
    },
    // 选择了查询时间
    balanceSearchCustomDataChanged(arg) {
      if (arg) {
        this.balanceSearchCondition = undefined;

        this.listUserAmountRecord();
        console.log("选择了余额明细查询时间", arg);
      }
    },
    // 切换了页码
    changePage(arg) {
      this.pageNum = arg;
      this.listUserAmountRecord();
      console.log("余额明细显示切换了页码", arg);
    },
  },
};
</script>
<style>
/* 自定义充值金额输入框的样式 */
.recharge-input .el-input .el-input__inner {
  width: 140px;
  height: 50px;
  background: #fff7f1;
  border-radius: 8px;
  border: 1px solid #ff6400;
  color: #ff6400;
  font-size: 16px;
  font-weight: 400;
}
.recharge-input .el-input .el-input__inner::placeholder {
  color: #ff6400;
  font-size: 16px;
  font-weight: 400;
}
.recharge-input .el-input .el-input__inner:focus,
.recharge-input .el-input .el-input__inner:hover {
  border: 1px solid #ff6400 !important;
}
</style>
<style lang="less" scoped>
.accent-balance {
  width: 100%;
  .main {
    background: #ffffff;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    // 导航条
    .balance-nav-div {
      display: flex;
      flex-direction: row;
      align-items: center;
      height: 68px;
      border-bottom: 1px solid #e5daeb;
      .balance-nav-text {
        height: 20px;
        font-size: 14px;
        font-weight: 400;
        color: #333333;
        line-height: 20px;
      }
      .balance-nav-text-selected {
        font-weight: 600;
        color: #6d5efe;
      }
      .nav-recharge-text {
        margin-left: 40px;
        margin-right: 60px;
      }
    }
    // 充值信息
    .recharge-data {
      height: 245px;
      display: flex;
      flex-direction: row;
      align-items: center;
      // 余额显示
      .balance-show-div {
        width: 269px;
        height: 197px;
        background: #f7f7f7;
        border-radius: 8px;
        margin-left: 24px;
        // 提示文本
        .tip-title {
          height: 20px;
          font-size: 14px;
          font-weight: 400;
          color: #333333;
          line-height: 20px;
          margin-left: 20px;
          margin-top: 20px;
        }
        // 余额
        .balance-show {
          width: 100%;
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: center;
          margin-top: 45px;
          .balance-text {
            height: 48px;
            font-size: 34px;
            font-weight: 600;
            color: #ff6400;
            line-height: 48px;
          }
          .balance-unit {
            height: 20px;
            font-size: 14px;
            font-weight: 400;
            color: #333333;
            line-height: 22px;
            margin-left: 8px;
          }
        }
      }
      // 充值金额选择
      .recharge-show {
        height: 197px;
        display: flex;
        flex-direction: column;
        margin-left: 40px;
        // 提示标语
        .tip-title {
          height: 20px;
          font-size: 14px;
          font-weight: 400;
          color: #333333;
          line-height: 20px;
        }
        // 金额选择
        .recharge-select-div {
          display: flex;
          flex-direction: row;
          margin-top: 20px;
          .recharge-item {
            position: relative;
            width: 140px;
            height: 50px;
            background: #ffffff;
            border-radius: 8px;
            border: 1px solid #d2d5dc;
            font-size: 16px;
            font-weight: 400;
            color: #1e2b4f;
            line-height: 50px;
            text-align: center;
            margin-right: 20px;
            .select-icon {
              position: absolute;
              width: 27px;
              bottom: -1px;
              right: -1px;
            }
          }
          .recharge-item-selected {
            background: #fff7f1;
            border-radius: 8px;
            border: 1px solid #ff6400;
            color: #ff6400;
          }
          .recharge-input {
            position: relative;
            .select-icon {
              position: absolute;
              width: 27px;
              bottom: 0px;
              right: 0px;
            }
          }
        }
        // 充值按钮
        .recharge-btn {
          width: 100px;
          height: 36px;
          background: #ff6400;
          border-radius: 23px;
          font-size: 14px;
          font-weight: 400;
          color: #ffffff;
          text-align: center;
          line-height: 36px;
          margin-top: 20px;
        }
      }
    }
    // 余额明细
    .balance-data {
      display: flex;
      flex-direction: column;
      padding: 24px 24px 24px 24px;
      // 查询条件显示
      .search-condition-show {
        height: 44px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
      }
      // 查询结果展示
      .search-result-show {
        margin-top: 24px;
      }
    }
  }
}
</style>
