import http from "@/utils/http";

const resquest = `${process.env.VUE_APP_BASE_URL}`;
// const resquest = `http://192.168.1.100:7000/express`

// 用户新增寄件人地址
export function uploadIdentityImage(data) {
  return http.post(`${resquest}/userInfo/sender/addSenderAddress`, data);
}
// 用户更新寄件人地址
export function updateSenderAddress(data) {
  return http.post(`${resquest}/userInfo/sender/updateSenderAddress`, data);
}
// 查询所有寄件人地址
export function listAllSenderAddress(data) {
  return http.post(`${resquest}/userInfo/sender/listAllSenderAddress`, data);
}
// 分页查询寄件人地址
export function listSenderAddress(data) {
  return http.post(`${resquest}/userInfo/sender/listSenderAddress`, data);
}
// 用户删除寄件人地址
export function delSenderAddress(params) {
  return http.get(`${resquest}/userInfo/sender/delSenderAddress`, params);
}
// 用户获取默认寄件人地址
export function defaultSenderAddress() {
  return http.get(`${resquest}/userInfo/sender/defaultSenderAddress`);
}
//邮编查询
export function getInfoByPostcode(params) {
  return http.get(`${resquest}/user/postcode/getInfoByPostcode`, params);
}
