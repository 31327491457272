<template>
  <div class="myAccount">
    <!-- 整体区域 -->
    <div class="main">
      <!-- 左侧导航栏 -->
      <div class="left-nav" ref="leftNav">
        <!-- 导航 -->
        <div class="nav" ref="nav">
          <!-- 账户信息 -->
          <div class="nav-item">
            <div class="nav-title">
              <img
                width="16"
                src="../../assets/img/myAccount/home/icon_nav_account.png"
              />
              <div class="title-text">账户信息</div>
            </div>
            <div class="nav-value">
              <div
                v-if="showMyAmount"
                class="value-item posi"
                @click="navclick(1)"
                :class="
                  curShowType == showType.myAccentBalance
                    ? 'value-item-selected'
                    : ''
                "
              >
                账户余额
              </div>
              <div
                class="value-item posi"
                @click="navclick(2)"
                :class="
                  curShowType == showType.myAccentData
                    ? 'value-item-selected'
                    : ''
                "
              >
                用户信息
              </div>
            </div>
          </div>
          <!-- 我的订单 -->
          <div class="nav-item">
            <div class="nav-title">
              <img
                width="16"
                src="../../assets/img/myAccount/home/icon_nav_order.png"
              />
              <div class="title-text">我的订单</div>
            </div>
            <div class="nav-value">
              <div
                class="value-item posi"
                @click="navclick(3)"
                :class="
                  curShowType == showType.orderSearch
                    ? 'value-item-selected'
                    : ''
                "
              >
                订单查询
              </div>
              <div
                class="value-item posi"
                @click="navclick(4)"
                :class="
                  curShowType == showType.surfaceSingleManagement
                    ? 'value-item-selected'
                    : ''
                "
              >
                面单管理
              </div>
            </div>
          </div>
          <!-- 常用工具 -->
          <div class="nav-item">
            <div class="nav-title">
              <img
                width="16"
                src="../../assets/img/myAccount/home/icon_nav_tool.png"
              />
              <div class="title-text">常用工具</div>
            </div>
            <div class="nav-value">
              <div
                class="value-item posi"
                @click="navclick(5)"
                :class="
                  curShowType == showType.addressBook
                    ? 'value-item-selected'
                    : ''
                "
              >
                地址簿
              </div>
              <div
                class="value-item posi"
                @click="navclick(6)"
                :class="
                  curShowType == showType.message ? 'value-item-selected' : ''
                "
              >
                <el-badge class="item" :is-dot="mesRedPoint">站内信</el-badge>
              </div>
              <!-- <el-badge is-dot class="value-item posi" @click="navclick(6)" :class='curShowType == showType.message?"value-item-selected":""'>站内信</el-badge> -->
            </div>
          </div>
        </div>
      </div>
      <!-- 右侧显示区域 -->
      <div class="right-show">
        <!-- 我的账户信息 -->
        <div v-if="curShowType == showType.myAccent">
          <div class="myAccountHome">
            <!-- 账户信息 -->
            <div class="accountData">
              <!-- 头像 -->
              <img class="account-img" src="../../assets/img/account_img.png" />
              <!-- 信息 -->
              <div class="data">
                <div class="name">{{ userdata.username }}</div>
                <div class="mail">{{ userdata.email }}</div>
                <div class="vip-offer-btn posi" @click="vipOfferBtnClick">
                  专属报价
                </div>
              </div>
              <!-- 余额 -->
              <div class="balance" v-if="showMyAmount">
                <div class="money">{{ myBalance }}</div>
                <div class="unit">账户余额（£）</div>
                <div class="recharge-btn posi" @click="rechargeBtnClick">
                  充值
                </div>
              </div>
            </div>
            <!-- 站内信和问题 -->
            <!-- <div class="account-tool"> -->
            <!-- 站内信 -->
            <!-- <div class="tool-message"></div> -->
            <!-- 问题 -->
            <!-- <div class="tool-question"></div> -->
            <!-- </div> -->
            <!-- 包裹情况 -->
            <div class="packageCondition">
              <!-- 上方标题框 -->
              <div class="top-div">
                <!-- 包裹情况 -->
                <div class="top-left">
                  <div class="top-left-icon"></div>
                  <div class="top-left-text">包裹情况</div>
                </div>
                <!-- 查看所有订单 -->
                <div class="top-right">
                  <div class="top-right-text posi" @click="getAllOrder">
                    查看所有订单
                  </div>
                  <i class="el-icon-d-arrow-right"></i>
                </div>
              </div>
              <!-- 包裹表格 -->
              <div class="package-div table-div">
                <el-table
                  :data="packageData"
                  style="width: 100%"
                  border
                  empty-text="暂无订单"
                >
                  <el-table-column
                    prop="lineName"
                    label="订单服务路线"
                    width="198"
                    :resizable="false"
                  >
                  </el-table-column>
                  <el-table-column
                    prop="orderNum"
                    label="订单号"
                    width="228"
                    :resizable="false"
                  >
                  </el-table-column>
                  <el-table-column
                    prop="consigneeName"
                    label="收件人"
                    width="170"
                    :resizable="false"
                  >
                  </el-table-column>
                  <el-table-column
                    prop="destination"
                    label="目的地"
                    width="156"
                    :resizable="false"
                  >
                  </el-table-column>
                  <el-table-column
                    prop="logisticsStatus"
                    label="状态"
                    :resizable="false"
                  >
                  </el-table-column>
                </el-table>
              </div>
            </div>
          </div>
        </div>
        <!-- 余额信息 -->
        <div v-if="curShowType == showType.myAccentBalance">
          <AccentBalance></AccentBalance>
        </div>
        <!-- 用户信息 -->
        <div v-if="curShowType == showType.myAccentData">
          <UserInformation></UserInformation>
        </div>
        <!-- 订单查询 -->
        <div v-if="curShowType == showType.orderSearch">
          <OrderSearch></OrderSearch>
        </div>
        <!-- 面单管理 -->
        <div v-if="curShowType == showType.surfaceSingleManagement">
          <SurfaceSingleManagement></SurfaceSingleManagement>
        </div>
        <!-- 地址簿 -->
        <div v-if="curShowType == showType.addressBook">
          <AddressPool></AddressPool>
        </div>
        <!-- 站内信 -->
        <div v-if="curShowType == showType.message">
          <Message @updataStatus="updataStatus"></Message>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { addEvent } from "../../utils/tool.js";
import AccentBalance from "./AccentBalance.vue";
import SurfaceSingleManagement from "./SurfaceSingleManagement.vue";
import OrderSearch from "./OrderSearch.vue";
import AddressPool from "./AddressPool.vue";
import UserInformation from "./UserInformation.vue";
import Message from "./Message.vue";
import { getQueryVariable } from "@/utils/common";
import { getRecentOrderPackage } from "@/api/user/user";
import { checkHaveNewMail, getUserAmount } from "@/api/user/user";
import { checkPaymentOrderStatus } from "@/api/order/order";
export default {
  name: "MyAccount",
  components: {
    AccentBalance,
    SurfaceSingleManagement,
    OrderSearch,
    AddressPool,
    UserInformation,
    Message,
  },
  data() {
    return {
      showType: {
        myAccent: 0, //我的账户
        myAccentBalance: 1, //账户余额
        myAccentData: 2, //用户信息
        orderSearch: 3, //订单查询
        surfaceSingleManagement: 4, //面单管理
        addressBook: 5, //地址簿
        message: 6, //站内信
      },
      // 右侧显示的操作类型
      // curShowType: this.$store.state.curShowType,
      //用户信息
      userdata: {
        username: "hallen",
        email: "120119110456@qq.com",
      },
      //包裹情况数据
      packageData: [],
      //站内信小红点
      mesRedPoint: true,
      //余额
      myBalance: 0,
      //订单支付id
      payId: this.$store.state.payId,

      // 账户余额隐藏
    };
  },
  computed: {
    curShowType: {
      get() {
        return this.$store.state.curShowType;
      },

      set(v) {
        this.$store.state.curShowType = v;
      },

      // return this.$store.state.curShowType;
    },
    showMyAmount() {
      let tags = localStorage.getItem("accessTags");
      return tags.indexOf("36") > -1 ? true : false;
    },
  },
  created() {
    // this.$route.query.showType ? (this.curShowType = this.$route.query.showType) : (this.curShowType = 0);
    this.curShowType = this.$store.state.curShowType;
    let flag = getQueryVariable("flag");
    if (flag == 0) {
      this.$message.error("已取消支付");
      let old_url = window.location.href;
      let new_url = old_url.substring(0, old_url.indexOf("?"));
      self.location = new_url; //1.当前直接替换了路径到url，实际上还是会进行跳转
      history.replaceState(null, null, new_url); //2.替换地址栏,直接将地址栏参数替换
    } else if (flag == 1) {
      // this.$message.success('支付成功')
      this.checkPaymentOrderStatus();
      let old_url = window.location.href;
      let new_url = old_url.substring(0, old_url.indexOf("?"));
      self.location = new_url; //1.当前直接替换了路径到url，实际上还是会进行跳转
      history.replaceState(null, null, new_url); //2.替换地址栏,直接将地址栏参数替换
    }
    // 对导航栏进行位置定位
    let _this = this;
    document.documentElement.scrollLeft = 0;
    addEvent(window, "scroll", function () {
      var sl = -Math.max(
        document.body.scrollLeft,
        document.documentElement.scrollLeft
      );
      if (document.body.clientWidth < 1400) {
        if (_this.$refs.nav) {
          _this.$refs.nav.style.left = 97.5 + sl + "px";
        }
      }
    });
    addEvent(window, "resize", function () {
      if (document.body.clientWidth < 1400) {
        if (_this.$refs.nav) {
          _this.$refs.nav.style.left = "97.5px";
        }
      } else {
        if (_this.$refs.nav) {
          _this.$refs.nav.style.left =
            97.5 + (document.body.clientWidth - 1400) / 2 + "px";
        }
      }
    });
    //获取用户信息
    let userInfo = this.$store.getters.getUserInfo;
    this.userdata = { ...userInfo };
    this.getPackage();
    this.getNewMsg();
    this.getUserAmount();
  },
  methods: {
    //获取账户余额
    getUserAmount() {
      console.log("账户余额111");
      getUserAmount().then((res) => {
        if (res.data.code === 0) {
          this.myBalance = res.data.data.data.amount;
        }
        console.log(res, "账户余额");
      });
    },
    //支付回调
    checkPaymentOrderStatus() {
      let upData = {
        id: this.payId,
      };
      checkPaymentOrderStatus(upData)
        .then((res) => {
          if (res.data.code === 0) {
            this.$message.success(res.data.message);
          } else {
            this.$message.error(res.data.message);
          }
        })
        .catch(() => {
          this.$message.error("支付失败");
        });
    },
    //获取包裹信息
    getPackage() {
      getRecentOrderPackage().then((res) => {
        if (res.data.code === 0) {
          this.packageData = res.data.data.list;
        } else {
          console.log(res, "包裹返回值");
        }
      });
    },
    //获取是否有新站内信
    getNewMsg() {
      checkHaveNewMail().then((res) => {
        if (res.data.code === 0) {
          if (res.data.data.count > 0) {
            this.mesRedPoint = true;
          } else {
            this.mesRedPoint = false;
          }
        }
        console.log(res, "是否有信息返回值");
      });
    },
    // 更新是否有未读站内信状态
    updataStatus(status) {
      this.mesRedPoint = status;
    },
    //导航按钮被点击
    navclick(arg) {
      this.$store.state.curShowType = arg;
      // if(this.curShowType == this.showType.message){
      //   this.mesRedPoint = false;
      // }
    },
    // 专属报价按钮被点击
    vipOfferBtnClick() {
      this.$router.push({
        path: `/FreightQuery`,
      });
    },
    // 去充值按钮被点击
    rechargeBtnClick() {
      this.$store.state.curShowType = this.showType.myAccentBalance;
    },
    // 查看所有订单
    getAllOrder() {
      this.$store.state.curShowType = this.showType.orderSearch;
    },
  },
};
</script>
<style></style>
<style lang="less" scoped>
.myAccount {
  background-color: #f4f4f4;
  min-height: 100%;
  .main {
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding: 20px;
    position: relative;
    // 左侧导航栏
    .left-nav {
      width: 150px;
      // 导航栏
      .nav {
        position: fixed;
        width: 150px;
        height: calc(100vh - 140px);
        display: flex;
        flex-direction: column;
        align-items: center;
        background-color: #fff;
        border-radius: 8px;
        padding-top: 20px;
        min-height: 450px;
        // 导航内容item
        .nav-item {
          width: 126px;
          display: flex;
          flex-direction: column;
          margin-bottom: 22px;
          // 导航内容标题
          .nav-title {
            display: flex;
            flex-direction: row;
            align-items: center;
            padding-left: 10px;
            .title-text {
              height: 22px;
              font-size: 16px;
              font-weight: bold;
              color: #1e2b4f;
              line-height: 22px;
              margin-left: 7px;
            }
          }
          // 导航具体内容
          .nav-value {
            margin-top: 12px;
            .value-item {
              width: 126px;
              height: 36px;
              background: #fff;
              border-radius: 8px;
              font-size: 14px;
              font-weight: 400;
              color: #333333;
              line-height: 20px;
              text-align: center;
              line-height: 36px;
              margin-bottom: 8px;
            }
            // .value-item:hover{
            //   background: #F3F4FD;
            //   color: #6D5EFE;
            // }
            .value-item-selected {
              background: #f3f4fd;
              color: #6d5efe;
            }
          }
        }
      }
    }
    // 右侧显示区域
    .right-show {
      width: 1030px;
      margin-left: 20px;
      // 我的账户信息
      .myAccountHome {
        display: flex;
        flex-direction: column;
        // 账户信息
        .accountData {
          display: flex;
          flex-direction: row;
          align-items: center;
          height: 160px;
          background: #ffffff;
          border-radius: 8px;
          // 头像
          .account-img {
            width: 82px;
            margin-left: 40px;
          }
          // 信息
          .data {
            display: flex;
            flex-direction: column;
            margin-left: 30px;
            .name {
              height: 28px;
              font-size: 20px;
              font-weight: 600;
              color: #1e2b4f;
              line-height: 28px;
            }
            .mail {
              height: 20px;
              font-size: 14px;
              font-weight: 400;
              color: #b1b6c3;
              line-height: 20px;
              margin-top: 2px;
            }
            .vip-offer-btn {
              height: 20px;
              font-size: 14px;
              font-weight: bold;
              color: #ff6400;
              line-height: 20px;
              margin-top: 12px;
            }
          }
          // 余额
          .balance {
            display: flex;
            flex-direction: column;
            margin-left: 71px;
            .money {
              height: 33px;
              font-size: 24px;
              font-weight: 600;
              color: #ff6400;
              line-height: 33px;
            }
            .unit {
              height: 20px;
              font-size: 14px;
              font-weight: 400;
              color: #b1b6c3;
              line-height: 20px;
            }
            .recharge-btn {
              width: 60px;
              height: 28px;
              background: #ff6400;
              border-radius: 8px;
              font-size: 14px;
              font-weight: 400;
              color: #ffffff;
              line-height: 28px;
              text-align: center;
              margin-top: 16px;
            }
          }
        }
        // 站内信和问题
        .account-tool {
          display: flex;
          flex-direction: row;
          margin-top: 20px;
          // 站内信
          .tool-message {
            width: 505px;
            height: 290px;
            background: #ffffff;
            border-radius: 8px;
          }
          // 问题
          .tool-question {
            width: 505px;
            height: 290px;
            background: #ffffff;
            border-radius: 8px;
            margin-left: 20px;
          }
        }
        // 包裹情况
        .packageCondition {
          width: 1030px;
          // height: 476px;
          background: #ffffff;
          border-radius: 8px;
          margin-top: 20px;
          display: flex;
          flex-direction: column;
          align-items: center;
          // 上方标题框
          .top-div {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            width: 982px;
            margin-top: 24px;
            .top-left {
              display: flex;
              flex-direction: row;
              align-items: center;
              .top-left-icon {
                width: 3px;
                height: 12px;
                background: #6d5efe;
                border-radius: 2px;
              }
              .top-left-text {
                height: 20px;
                font-size: 14px;
                font-weight: bold;
                color: #1e2b4f;
                line-height: 20px;
                margin-left: 6px;
              }
            }
            .top-right {
              display: flex;
              flex-direction: row;
              align-items: center;
              .top-right-text {
                height: 20px;
                font-size: 14px;
                font-weight: 400;
                color: #1e2b4f;
                line-height: 20px;
                margin-right: 6px;
              }
              .el-icon-d-arrow-right {
                font-size: 14px;
                font-weight: bold;
                color: #1e2b4f;
              }
            }
          }
          // 表格
          .package-div {
            width: 982px;
            margin-top: 24px;
            margin-bottom: 23px;
          }
        }
      }
    }
  }
}
</style>
