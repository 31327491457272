<template>
  <div class="user-information">
    <div class="main" v-loading="loading">
      <el-form
        ref="userForm"
        :rules="rules"
        :model="userFormData"
        label-width="142px"
      >
        <el-form-item class="normal-input" label="国家：" prop="countryName">
          <el-input disabled v-model="userFormData.countryName"></el-input>
        </el-form-item>
        <el-form-item class="normal-input" label="用户名：" prop="username">
          <el-input disabled v-model="userFormData.username"></el-input>
        </el-form-item>
        <el-form-item label="电话：" prop="phone" class="iphone-input">
          <img
            :src="
              userFormData.globalRoaming !== '+86'
                ? require('../../assets/img/login/fb-uk.png')
                : require('../../assets/img/login/fb-china.png')
            "
            class="country-img"
          />
          <el-input
            class="phone-input-all"
            placeholder="请输入手机号"
            v-model="userFormData.phone"
            @change="phoneChanged"
          >
            <el-select
              v-model="userFormData.globalRoaming"
              @change="globalRoamingChange"
              slot="prepend"
              placeholder="请选择"
              class="country-select"
              popper-class="select-country"
            >
              <el-option
                v-for="item in country_config"
                :key="item.value"
                :label="item.value"
                :value="item.value"
              >
                <img :src="item.url" class="country-option-img" />
                <span>{{ item.label }}</span>
              </el-option>
            </el-select>
          </el-input>
        </el-form-item>
        <el-form-item v-if="showCode" label="验证码：" prop="code">
          <GetCode
            class="get-code"
            style="width: 280px"
            v-model="userFormData.code"
            :phone="userFormData.phone"
            :globalRoaming="userFormData.globalRoaming"
          ></GetCode>
        </el-form-item>
        <el-form-item class="normal-input" label="电子邮箱：" prop="email">
          <el-input v-model="userFormData.email"></el-input>
        </el-form-item>
        <el-form-item class="normal-input" label="邮编：" prop="postCode">
          <el-input v-model="userFormData.postCode"></el-input>
        </el-form-item>
        <el-form-item class="normal-input" label="市：" prop="city">
          <el-input v-model="userFormData.city"></el-input>
        </el-form-item>
        <el-form-item class="normal-input" label="QQ：" prop="qqNumber">
          <el-input v-model="userFormData.qqNumber"></el-input>
        </el-form-item>
        <el-form-item class="normal-input" label="微信：" prop="wechatNumber">
          <el-input v-model="userFormData.wechatNumber"></el-input>
        </el-form-item>
      </el-form>
      <el-button class="saveBtn" @click="saveData">保存</el-button>
    </div>
  </div>
</template>

<script>
import GetCode from "../../components/login/getCode.vue";
import { getUserInfo, updateUserInfo } from "../../api/login/login";
export default {
  name: "UserInformation",
  components: { GetCode },
  data() {
    // 此处自定义校验邮箱格式js逻辑
    var emailReg = /\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*/;
    var validateEmail = (rule, value, callback) => {
      if (!value) callback(new Error("请输入Email"));
      if (!emailReg.test(value)) {
        callback(new Error("Email地址不合法"));
      } else {
        callback();
      }
    };
    // 此处自定义校验英国邮编js逻辑
    var zipCodeReg =
      /^([Gg][Ii][Rr] 0[Aa]{2})|((([A-Za-z][0-9]{1,2})|(([A-Za-z][A-Ha-hJ-Yj-y][0-9]{1,2})|(([A-Za-z][0-9][A-Za-z])|([A-Za-z][A-Ha-hJ-Yj-y][0-9]?[A-Za-z]))))\s?[0-9][A-Za-z]{2})$/;
    var validatePhone = (rule, value, callback) => {
      if (!value) callback(new Error("请输入英国邮编"));
      if (!zipCodeReg.test(value)) {
        callback(new Error("邮编格式不正确，请输入英国邮编"));
      } else {
        callback();
      }
    };
    return {
      loading: false,
      // 是否显示验证码
      showCode: false,
      // 表单数据
      userFormData: {
        city: "",
        countryName: "",
        email: "",
        globalRoaming: "",
        phone: "",
        postCode: "",
        qqNumber: "",
        username: "",
        wechatNumber: "",
        code: "",
      },
      // 记录原始的电话号码数据
      oldPhoneData: {
        globalRoaming: "",
        phone: "",
      },
      // 表单校验
      rules: {
        phone: [
          { required: true, message: "请输入正确的手机号", trigger: "blur" },
        ],
        code: [{ required: true, message: "验证码错误", trigger: "blur" }],
        email: [{ required: true, validator: validateEmail, trigger: "blur" }],
       
        city: [{ required: true, message: "请输入城市", trigger: "blur" }],
      },
      // 国家手机号配置文件
      country_config: [
        {
          label: "China(中国) +86",
          value: "+86",
          url: require("../../assets/img/login/fb-china.png"),
        },
        {
          label: "United Kingdom +44",
          value: "+44",
          url: require("../../assets/img/login/fb-uk.png"),
        },
      ],
    };
  },
  mounted() {
    this.getUserInfo();
  },
  methods: {
    // 获取用户信息
    getUserInfo() {
      this.loading = true;
      getUserInfo().then((res) => {
        if (res.data && res.data.data && res.data.data.data) {
          this.userFormData = { ...res.data.data.data };
          this.oldPhoneData.globalRoaming = res.data.data.data.globalRoaming;
          this.oldPhoneData.phone = res.data.data.data.phone;
        }
        this.loading = false;
      });
    },
    // 电话号码进行了修改
    phoneChanged() {
      if (this.userFormData.phone != this.oldPhoneData.phone) {
        this.showCode = true;
      } else {
        this.showCode = false;
      }
    },
    // 区号进行了修改
    globalRoamingChange() {
      if (this.userFormData.globalRoaming != this.oldPhoneData.globalRoaming) {
        this.showCode = true;
      } else {
        this.showCode = false;
      }
    },
    // 保存数据
    saveData() {
      this.$refs.userForm.validate((valid) => {
        if (valid) {
          let data = { ...this.userFormData };
          this.loading = true;
          updateUserInfo(data).then((res) => {
            if (
              res.data &&
              res.data.code == 0 &&
              res.data.data &&
              res.data.data.data
            ) {
              this.userFormData = Object.assign(
                {},
                this.userFormData,
                res.data.data.data
              );
              this.$message({
                message: "修改成功",
                type: "success",
                duration: 3000,
              });
            } else if (res.data && res.data.code != 0) {
              this.$message({
                message: res.data.message,
                type: "error",
                duration: 3000,
              });
            }
            this.loading = false;
          });
        } else {
          return;
        }
      });
    },
  },
};
</script>

<style>
.user-information .el-form-item__label {
  padding: 0px 20px 0px 0px;
}
.user-information .normal-input .el-input {
  width: 280px;
}
.user-information .el-form-item__error {
  top: 31%;
  left: 34%;
}
.user-information .normal-input .el-input__inner,
.user-information .get-code .el-input__inner {
  height: 44px;
  padding: 0 24px;
}
.user-information .el-input.is-disabled .el-input__inner {
  color: #1e2b4f;
}
.user-information .get-code .el-button {
  height: 100%;
}
/* 下拉框的选项 */
.select-country .country-option-img {
  margin-right: 12px;
  display: inline-block;
  vertical-align: middle;
  width: 24px;
}
/* 下拉框中选中文字颜色 */
.select-country .el-select-dropdown__item.selected {
  color: #6d5efe;
}
</style>

<style lang="less" scoped>
.user-information {
  width: 100%;
  .main {
    background: #ffffff;
    border-radius: 8px;
    padding: 50px 0px;
    // 手机号登录框
    .iphone-input {
      position: relative;
      //手机号输入框
      .phone-input-all {
        width: 280px;
        ::v-deep .el-input__inner {
          height: 44px;
          border-top-left-radius: 0;
          border-bottom-left-radius: 0;
        }
      }
      // 选中国家图片
      .country-img {
        width: 20px;
        position: absolute;
        z-index: 10;
        top: 50%;
        left: 24px;
        transform: translateY(-50%);
      }
      // 国家下拉框选项
      .country-select {
        width: 120px;
        position: relative;
        padding-left: 36px;
      }
      // 文本框icon的宽度加大
      ::v-deep .el-input__icon {
        width: 40px;
      }
      // 输入框前边下拉框边框样式
      ::v-deep .el-input-group .country-select .el-input__inner {
        border-color: transparent !important;
      }
      // 输入框前边下拉框边框圆角
      ::v-deep .el-input-group__prepend {
        border-radius: 22px 0 0 22px;
        background: #fff;
        border: 1px solid #d2d5dc;
        border-right: 0px;
      }
    }
    //保存按钮
    .saveBtn {
      width: 72px;
      height: 44px;
      background: #6d5efe;
      border-radius: 22px;
      font-size: 16px;
      font-weight: bold;
      color: #ffffff;
      margin-left: 142px;
      margin-top: 16px;
    }
  }
}
</style>
