<template>
  <div class="OrderSearch">
    <div class="main">
      <!-- 顶部提示 -->
      <div v-if="tipShow" class="top-tip">
        <div class="top-tip-title">
          您可以在此页面内下载包裹快递面单、查看包裹订单信息、追踪包裹
        </div>
        <div class="top-tip-content">
          下单成功后的包裹需要点击"查看面单"打印包裹快递面单，确保面单条码足够清晰并贴在箱子面积最大的一面，然后可以根据你选择的投递服务送去相应的投递点或等待司机上门取件。
        </div>
        <i
          class="el-icon-close posi top-tip-close"
          @click="tipShow = false"
        ></i>
      </div>
      <!-- 查询显示区域 -->
      <div class="order-search-show">
        <!-- 查询条件 -->
        <div class="search-condition-show">
          <!-- 条件第一排 -->
          <div class="line-show">
            <!-- 收件人 -->
            <div class="input-item">
              <MyInput
                :placeholder="'请输入'"
                :label="'收件人：'"
                v-model="searchCondition.name"
              ></MyInput>
            </div>
            <!-- 收件人电话 -->
            <div class="input-item">
              <MyInput
                :placeholder="'请输入'"
                :label="'收件人电话：'"
                v-model="searchCondition.phone"
                @input="
                  (v) => (searchCondition.phone = v.replace(/[^\d]/g, ''))
                "
              ></MyInput>
            </div>
            <!-- 单号 -->
            <div class="input-item">
              <MyInput
                :placeholder="'请输入'"
                :label="'单号：'"
                v-model="searchCondition.number"
              ></MyInput>
            </div>
            <!-- 服务线路 -->
            <div class="input-item">
              <Myselect
                :label="'服务：'"
                v-model="searchCondition.serve"
                :options="wayPath"
              ></Myselect>
            </div>
          </div>
          <!-- 条件第二排 -->
          <div class="line-show">
            <!-- 服务线路 -->
            <div class="input-item">
              <Myselect
                :label="'物流状态：'"
                v-model="searchCondition.status"
                :options="deliveryStatus"
              ></Myselect>
            </div>
            <!-- 时间选择 -->
            <div class="select-data-div">
              <el-date-picker
                popper-class="date-picker"
                v-model="searchCondition.date"
                type="daterange"
                :editable="false"
                value-format="yyyy-MM-dd"
                range-separator="至"
                start-placeholder="开始时间"
                end-placeholder="结束时间"
              >
              </el-date-picker>
            </div>
            <!-- 操作按钮 -->
            <div class="btn-div">
              <div class="search-btn posi" @click="getList">搜索</div>
              <div class="merge-btn posi" @click="mergeBtnClick">合并面单</div>
              <div class="export-btn posi" @click="exportDataClick">
                导出数据
              </div>
            </div>
          </div>
        </div>
        <!-- 查询结果 -->
        <div class="search-result-show">
          <!-- 身份证条件选择 -->
          <div class="id-card-search-div checkbox-div">
            <el-checkbox-group v-model="idCardSearchList" @change="getList">
              <el-checkbox label="1">查看身份认证失败的订单</el-checkbox>
              <el-checkbox label="2">查看待上传身份证的订单</el-checkbox>
            </el-checkbox-group>
          </div>
          <!-- 搜索结果 -->
          <div class="table-div search-table">
            <el-table
              :data="seachShowData"
              style="width: 100%"
              border
              empty-text="暂无数据"
              @selection-change="handleSelectionChange"
            >
              <el-table-column
                type="selection"
                width="40"
                :resizable="false"
              ></el-table-column>
              <el-table-column
                prop="createTime"
                label="时间"
                width="97"
                :resizable="false"
              >
              </el-table-column>
              <el-table-column
                prop="orderNum"
                label="订单号"
                width="180"
                :resizable="false"
              >
                <template slot-scope="scope">
                  <div class="oddlist-item">
                    <div class="oddlist-item-text">订单号：</div>
                    <div class="oddlist-item-text">
                      {{ scope.row.orderNum }}
                    </div>
                    <div style="height: 12px"></div>
                  </div>
                  <!-- <div class="oddlist-item oddlist-item-mid">
                    <div class="oddlist-item-text">面单号：</div>
                    <div class="oddlist-item-text">{{scope.row.faceNum}}</div>
                  </div> -->
                  <div class="oddlist-item">
                    <div class="oddlist-item-text">国内邮单号：</div>
                    <div class="oddlist-item-text">{{ scope.row.mailNum }}</div>
                  </div>
                </template>
              </el-table-column>
              <el-table-column
                prop="lineName"
                label="服务"
                width="99"
                :resizable="false"
              >
              </el-table-column>
              <el-table-column
                prop="senderName"
                label="寄件人"
                width="75"
                :resizable="false"
              >
              </el-table-column>
              <el-table-column
                prop="consigneeName"
                label="收件人"
                width="111"
                :resizable="false"
              >
                <template slot-scope="scope">
                  <div class="recipients-div">
                    <div>{{ scope.row.consigneeName }}</div>
                    <div
                      v-if="scope.row.consigneeIdentityState == 2"
                      class="recipients-tip"
                    >
                      身份证已上传
                    </div>
                    <div
                      v-if="scope.row.consigneeIdentityState == 0"
                      class="recipients-tip"
                    >
                      身份证待上传
                    </div>
                    <div
                      v-if="scope.row.consigneeIdentityState == 1"
                      class="recipients-tip"
                    >
                      身份证认证失败
                    </div>
                    <div
                      v-if="
                        scope.row.consigneeIdentityState == 0 ||
                        scope.row.consigneeIdentityState == 1
                      "
                      class="recipients-upload posi"
                      @click="idCardUploadClicked(scope.$index, scope.row)"
                    >
                      上传
                    </div>
                  </div>
                </template>
              </el-table-column>
              <el-table-column
                prop="destination"
                label="目的地"
                :resizable="false"
              >
                <template slot-scope="scope">
                  <div>{{ scope.row.destination }}</div>
                </template>
              </el-table-column>
              <!-- <el-table-column prop="weight" label="申报重量(KG)" width="77" :resizable="false">
              </el-table-column> -->
              <el-table-column
                prop="amount"
                label="金额(£)"
                width="70"
                :resizable="false"
              >
              </el-table-column>
              <el-table-column
                prop="logisticsStatus"
                label="状态"
                width="95"
                :resizable="false"
              >
                <template slot-scope="scope">
                  <div class="state-div">
                    <div
                      class="trace-div posi"
                      @click="traceClicked(scope.$index, scope.row)"
                    >
                      <img
                        width="19"
                        height="18"
                        src="../../assets/img/myAccount/OrderSearch/icon_map.png"
                      />
                      <div class="trace-text">跟踪</div>
                    </div>
                    <div class="state-div">
                      <div>{{ scope.row.logisticsStatus }}</div>
                    </div>
                  </div>
                </template>
              </el-table-column>
              <el-table-column label="操作" :resizable="false" width="80">
                <template slot-scope="scope">
                  <div class="edit-div">
                    <div
                      v-if="scope.row.logisticsStatusId != 10"
                      class="edit-text face-text posi"
                      @click="lookfaceClicked(scope.$index, scope.row)"
                    >
                      查看面单
                    </div>
                    <div
                      class="edit-text face-text posi"
                      @click="lookOddClicked(scope.$index, scope.row)"
                    >
                      查看订单
                    </div>
                    <div
                      class="edit-text posi"
                      v-if="
                        scope.row.logisticsStatusId == 1 &&
                        parseInt(Date.now() / 1000) - scope.row.payTime < 86400
                      "
                      @click="cancelOrder(scope.row)"
                    >
                      取消订单
                    </div>
                  </div>
                </template>
              </el-table-column>
            </el-table>
          </div>
          <!-- 页码 -->
          <div class="pagination-div">
            <!-- <el-pagination
              background
              layout="prev, pager, next"
              :hide-on-single-page="true"
              :total="pageCount"
              :current-page.sync="currentPage"
              @current-change="currentPageChanged"
            >
            </el-pagination> -->
            <Pagination
              :total="pageCount"
              :page.sync="currentPage"
              :limit.sync="pageSize"
              @pagination="getList"
            />
          </div>
          <!-- 订单信息展示 -->
          <div class="drawer-div">
            <el-drawer
              :title="'订单：' + showOddData.orderNum"
              :visible.sync="trigger"
              direction="rtl"
              :size="1000"
            >
              <div class="drawer-content">
                <!-- 卡片信息 -->
                <div class="card-div">
                  <!-- 订单信息 -->
                  <div class="card-item">
                    <div class="title">
                      <div class="icon"></div>
                      <div class="title-text">订单信息</div>
                      <img
                        class="img-state"
                        width="15"
                        height="14"
                        src="../../assets/img/myAccount/OrderSearch/icon_packger.png"
                      />
                      <div class="status">
                        {{ showOddData.logisticsStatus }}
                      </div>
                    </div>
                    <div class="card-content">
                      <div class="content-item">
                        <div class="name">国际服务：</div>
                        <div class="value">{{ showOddData.serviceName }}</div>
                      </div>
                      <div class="content-item">
                        <div class="name">重量：</div>
                        <div class="value">{{ showOddData.weight }}</div>
                      </div>
                      <div class="content-item">
                        <div class="name">订单体积：</div>
                        <div class="value">
                          {{
                            `${showOddData.length}cm x ${showOddData.width}cm x ${showOddData.height}cm`
                          }}
                        </div>
                      </div>
                      <div class="content-item">
                        <div class="name">总金额：</div>
                        <div class="value account">
                          {{ showOddData.amount.amount }}
                        </div>
                      </div>
                      <div class="content-item">
                        <div class="name">额外购买保险：</div>
                        <div class="value">
                          {{ showOddData.amount.insuranceCost }}
                        </div>
                      </div>
                      <div class="content-item">
                        <div class="name">订单日期：</div>
                        <div class="value">{{ showOddData.createTime }}</div>
                      </div>
                    </div>
                  </div>
                  <!-- 地址信息 -->
                  <div class="card-item">
                    <div class="title">
                      <div class="icon"></div>
                      <div class="title-text">地址信息</div>
                    </div>
                    <div class="card-content">
                      <div class="address-div sender-div">
                        <div class="tip">寄件人：</div>
                        <div class="value">
                          {{ showOddData.senderName }}
                          {{ showOddData.senderPhone }}
                          {{ showOddData.senderInfoJson.address[0] }}
                          {{ showOddData.senderInfoJson.address[1] }}
                          {{ showOddData.senderInfoJson.address[2] }}
                          {{ showOddData.senderInfoJson.address[3] }}
                        </div>
                      </div>
                      <div class="address-div recipients-div">
                        <div class="tip">收件人：</div>
                        <div class="value">
                          {{ showOddData.consigneeName }}
                          {{ showOddData.consigneePhone }}
                          {{ showOddData.consigneeInfoJson.address[0] }}
                          {{ showOddData.consigneeInfoJson.address[1] }}
                          {{ showOddData.consigneeInfoJson.address[2] }}
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- 备注 -->
                  <div class="card-item">
                    <div class="title">
                      <div class="icon"></div>
                      <div class="title-text">备注</div>
                    </div>
                    <div class="card-content">
                      <div class="remark-div">
                        {{ showOddData.note }}
                      </div>
                    </div>
                  </div>
                </div>
                <!-- 物品明细 -->
                <div class="goods-div">
                  <div class="title">
                    <div class="icon"></div>
                    <div class="title-text">物品明细</div>
                  </div>
                  <div class="table-div">
                    <el-table
                      :data="showOddData.goodsList"
                      style="width: 100%"
                      border
                      empty-text="暂无数据"
                    >
                      <el-table-column
                        prop="name"
                        label="物品名称"
                        width="236"
                        :resizable="false"
                      >
                      </el-table-column>
                      <el-table-column
                        prop="type"
                        label="类别"
                        width="145"
                        :resizable="false"
                      >
                      </el-table-column>
                      <el-table-column
                        prop="price"
                        label="单价(RMB)"
                        width="95"
                        :resizable="false"
                      >
                      </el-table-column>
                      <el-table-column
                        prop="number"
                        label="申报数量"
                        width="88"
                        :resizable="false"
                      >
                      </el-table-column>
                      <el-table-column
                        prop="hsCode"
                        label="HS编码"
                        width="108"
                        :resizable="false"
                      >
                      </el-table-column>
                      <el-table-column
                        prop="suttle"
                        label="单位重量(KG)"
                        width="89"
                        :resizable="false"
                      >
                      </el-table-column>
                      <el-table-column
                        prop="brand"
                        label="品牌"
                        width="74"
                        :resizable="false"
                      >
                      </el-table-column>
                      <el-table-column
                        prop="norms"
                        label="规格/货号"
                        :resizable="false"
                      >
                      </el-table-column>
                    </el-table>
                  </div>
                </div>
                <!-- 费用明细 -->
                <div class="cost-div">
                  <div class="title">
                    <div class="icon"></div>
                    <div class="title-text">费用明细</div>
                  </div>
                  <div class="cost-content">
                    <!-- 总金额 -->
                    <div class="all-cost-div">
                      <div class="tip">总金额（英镑)：</div>
                      <div class="all-cost-text">
                        {{ showOddData.realPrice }}
                      </div>
                    </div>
                    <!-- 费用明细第一列 -->
                    <div class="cost-div">
                      <div class="cost-item">
                        <div class="name">国际运费：</div>
                        <div class="value">
                          {{ showOddData.amount.internationalShipping }} ￡
                        </div>
                      </div>
                      <div class="cost-item">
                        <div class="name">预缴税金：</div>
                        <div class="value">
                          {{ showOddData.amount.prepaidTax }} ￡
                        </div>
                      </div>
                      <div v-if="showOddData.discount > 0" class="cost-item">
                        <div class="name">优惠：</div>
                        <div class="value">
                          {{ showOddData.discount }} ￡
                        </div>
                      </div>
                    </div>
                    <!-- 费用明细第er列 -->
                    <div class="cost-div">
                      <div class="cost-item">
                        <div class="name">保险费：</div>
                        <div class="value">
                          {{ showOddData.amount.insuranceCost }} ￡
                        </div>
                      </div>
                      <div class="cost-item">
                        <div class="name">燃油附加费：</div>
                        <div class="value">
                          {{ showOddData.amount.fuelSurcharge }} ￡
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </el-drawer>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MyInput from "../../components/input.vue";
import Myselect from "../../components/select.vue";
import { listOrder } from "@/api/user/user";
import {
  getOrderPackageListDetailById,
  showFaceSheetById,
  showMergeFaceSheetByIds,
  showAllRecordByOrderPackageId,
} from "@/api/user/user";
import {
  getAllServiceLine,
  getAllShipmentLogisticsList,
  downloadOrderInfoByIds,
  cancelCustomerOrder,
} from "@/api/order/order";
import Pagination from "@/components/pagination.vue";
export default {
  name: "OrderSearch",
  components: {
    MyInput,
    Myselect,
    Pagination,
  },
  data() {
    return {
      //是否显示订单详情
      trigger: false,
      //是否显示提示
      tipShow: true,
      //线路类型
      wayPath: [],
      // 物流状态
      deliveryStatus: [],
      //搜索条件
      searchCondition: {
        name: "",
        phone: "",
        number: "",
        serve: "",
        status: "",
        date: "",
      },
      //身份证条件选择
      idCardSearchList: [],
      //搜索结果
      seachShowData: [],
      // 查看订单的数据
      showOddData: {
        //实际价格
        realPrice: "",
        //订单号
        orderNum: "",
        //订单信息状态
        logisticsStatus: "",
        //国际服务
        serviceName: "两罐奶粉",
        //重量
        weight: 10,
        //备注
        note: "",
        //金额
        amount: {
          insuranceCost: 0,
          amount: 0.0,
          internationalShipping: 0,
          prepaidTax: 0,
          fuelSurcharge: 0.0,
        },
        //订单信息
        oddData: {
          //订单体积
          volume: "5cm x 5cm x 5cm",

          //订单日期
          date: "2021-12-01",
        },
        //地址信息
        senderInfoJson: {
          //寄件人
          zipCode: "100001",
          //收件人
          address: ["湖北", "荆州", "沙市", "???"],
        },
        consigneeInfoJson: {
          address: "",
          city: [],
        },
        //备注
        remark: "没有备注",
        //物品明细
        goodsList: [
          {
            name: "Nido雀巢全脂高钙成人奶粉900g",
            type: "厨卫用具及小家电",
            price: "70.00",
            number: "10000",
            eanCode: "000000000",
            suttle: "00.00",
            brand: "爱他美",
            norms: "900g",
          },
          {
            name: "Nido雀巢全脂高钙成人奶粉900g",
            type: "厨卫用具及小家电",
            price: "70.00",
            number: "10000",
            eanCode: "000000000",
            suttle: "00.00",
            brand: "爱他美",
            norms: "900g",
          },
        ],
        //费用明细
        costData: {
          //总金额
          cost: "20000.00",
          //国际运费
          internationalShippingFee: "￡20000.00",
          //境内费用
          inCharge: "￡20000.00",
          //预缴税金
          prepayTaxes: "￡20000.00",
          //附加费
          additionalCharge: "￡20000.00",
          //保险费
          premium: "￡20000.00",
          //燃油附加费
          fuelSurcharge: "￡20000.00",
          //增值服务费
          addedServiceFee: "￡20000.00",
        },
      },
      // 选择的面单单
      multipleSelection: "",
      // 分页总条数
      pageCount: 0,
      // 当前页码
      currentPage: 1,
      pageSize: 50,
      //当前点击订单详情的id
      currentOrderId: 0,
      //跟踪的订单
      trackId: 0,
    };
  },
  created() {
    this.getList();
    this.getAllShipmentLogisticsList();
    this.getAllServiceLine();
  },
  methods: {
    // 获取订单数据
    getList() {
      let upData = {
        consigneeName: this.searchCondition.name,
        consigneePhone: this.searchCondition.phone,
        endTime: this.searchCondition.date[1],
        identityUnUpload: this.idCardSearchList.includes("2") ? 1 : undefined,
        identityFail: this.idCardSearchList.includes("1") ? 1 : undefined,
        lineId: this.searchCondition.serve,
        logisticsStatusId: this.searchCondition.status,
        orderNum: this.searchCondition.number,
        pageNum: this.currentPage,
        pageSize: this.pageSize,
        startTime: this.searchCondition.date[0],
      };
      listOrder(upData).then((res) => {
        if (res.data.code === 0) {
          this.seachShowData = res.data.data.list.list;
          this.pageCount = res.data.data.list.totalCount;
        }
        console.log(res, "订单接口返回值");
      });
    },
    //订单详情
    getOrderDetail() {
      let updata = {
        orderId: this.currentOrderId,
      };
      getOrderPackageListDetailById(updata).then((res) => {
        if (res.data.code === 0) {

          this.showOddData = res.data.data.result;
          this.showOddData.realPrice = (res.data.data.result.amount.amount - res.data.data.result.discount).toFixed(2);
        } else {
          return this.$message.error(res.data.message);
        }
        console.log(res, "订单详情接口返回值");
        console.log(this.showOddData, "订单详情值");
      });
    },
    // 获取服务路线分类
    getAllServiceLine() {
      getAllServiceLine().then((res) => {
        if (res.data.code === 0) {
          this.wayPath = res.data.data.list;
        }
        console.log(res, "服务路线接口返回值", this.wayPath);
      });
    },
    //获取物流状态
    getAllShipmentLogisticsList() {
      getAllShipmentLogisticsList().then((res) => {
        if (res.data.code === 0) {
          this.deliveryStatus = res.data.data.list;
        }
        console.log(res, "物流状态接口返回值");
      });
    },

    showAllRecordByOrderPackageId() {
      let update = {
        id: this.trackId,
      };
      showAllRecordByOrderPackageId(update).then((res) => {
        console.log(res, "包裹追踪接口返回值");
      });
    },
    //查看面单
    showFaceSheetById(id) {
      let upData = {
        id: id,
      };
      showFaceSheetById(upData).then((res) => {
        if (res.data.code === 0) {
          window.open(res.data.data.path, "_blank");
        }
      });
    },
    //合并面单
    showMergeFaceSheetByIds(ids) {
      if (ids.length == 0) {
        this.$message.error("请选择需要合并的面单");
        return;
      }
      showMergeFaceSheetByIds(ids).then((res) => {
        if (res.data.code === 0) {
          window.open(res.data.data.path, "_blank");
        }
        console.log(res, "合并面单");
      });
    },
    //下载方法
    download(href, title) {
      const a = document.createElement("a");
      a.setAttribute("href", href);
      a.setAttribute("download", title);
      a.click();
    },
    //导出数据
    downloadOrderInfoByIds(ipo) {
      if (ipo.orderIds.length == 0) {
        this.$message.error("请选择需要导出的面单");
        return;
      }
      let _that = this;
      let xhr = new XMLHttpRequest();
      let url = downloadOrderInfoByIds();
      xhr.open("POST", url, true);
      xhr.responseType = "blob";
      let token = localStorage.getItem("token");
      xhr.setRequestHeader("verification", token);
      xhr.setRequestHeader("Content-Type", "application/json");
      xhr.withCredentials = true;

      xhr.onload = function () {
        if (xhr.status === 200) {
          // 返回200
          let blob = this.response;
          let reader = new FileReader();
          reader.readAsDataURL(blob); // 转换为base64，可以直接放入a表情href
          // 文件流转url
          // let url = window.webkitURL.createObjectURL(blob);
          reader.onload = function (e) {
            let base64 = e.target.result;
            console.log(base64, "base64");
            _that.download(base64, `订单详情.xlsx`);
          };
        } else {
          _that.$message.error("下载订单详情失败");
        }
      };
      let ipos = JSON.stringify(ipo);
      xhr.send(ipos);
      console.log("pdf进入fasong");
    },
    // 上传身份证按钮被点击
    idCardUploadClicked(arg1, arg2) {
      console.log("上传身份证按钮被点击", arg1, arg2);
      let routeUrl = this.$router.resolve({
        path: "/IdCardUpload",
      });
      window.open(routeUrl.href, "_blank");
    },
    // 跟踪订单按钮被点击
    traceClicked(arg1, arg2) {
      console.log("跟踪订单按钮被点击", arg2.id);
      this.trackId = arg2.id;
      this.showAllRecordByOrderPackageId();
      let { href } = this.$router.resolve({
        path: "/track",
        query: { id: arg2.id },
      });
      window.open(href, "_blank");
    },
    // 合并面单按钮被点击
    mergeBtnClick() {
      console.log("合并面单按钮被点击");
      let signArr = this.multipleSelection;
      let upDataArr = [];
      for (let i = 0; i < signArr.length; i++) {
        upDataArr.push(signArr[i].id);
      }
      this.showMergeFaceSheetByIds(upDataArr);
    },
    //导出数据被点击
    exportDataClick() {
      console.log("导出数据被点击");
      let signArr = this.multipleSelection;
      let upDataArr = [];
      for (let i = 0; i < signArr.length; i++) {
        upDataArr.push(signArr[i].orderNum);
      }
      let upData = {
        orderIds: upDataArr,
      };
      this.downloadOrderInfoByIds(upData);
    },
    // 查看订单被点击
    lookOddClicked(arg1, arg2) {
      console.log("查看订单被点击", arg2);
      this.trigger = true;
      this.showOddData.orderNum = arg2.orderNum;
      this.currentOrderId = arg2.id;
      this.getOrderDetail();
    },
    // 查看面单被点击
    lookfaceClicked(arg1, arg2) {
      console.log("查看面单被点击", arg1, arg2);
      if (arg2.couldViewFace == 0) {
        return;
      }
      this.showFaceSheetById(arg2.id);
    },
    // 取消订单
    cancelOrder(e) {
      console.log(e);
      let now = parseInt(Date.now() / 1000);
      let days = 3600 * 24;
      if (now - e.payTime < days && e.logisticsStatusId == 1) {
        console.log("满足条件");
        cancelCustomerOrder({ orderId: e.id }).then((res) => {
          console.log("cancelCustomerOrder ", res);
          if (res.data.code == 0) {
            this.$message({
              message: "订单取消成功，退款已返还账户余额内",
              type: "success",
            });
            setTimeout(() => {
              this.getList();
            }, 1500);
          }
        });
      } else {
        this.$message.error("订单已不可取消");
      }
    },
    // 选择的面单发生变化
    handleSelectionChange(args) {
      console.log("选择的面单列表发生变化", args);
      this.multipleSelection = args;
    },
    // 切换页码
    currentPageChanged(arg) {
      console.log("切换了页码", arg);
      this.currentPage = arg;
      //请求数据
      this.getList();
    },
  },
};
</script>

<style>
/* table样式 */
.OrderSearch .search-table .el-table__row > td:nth-child(2) .cell,
.OrderSearch .search-table .el-table__row > td:nth-child(4) .cell {
  text-align: left;
}
/* 抽屉的样式 */
.drawer-div .el-drawer__header {
  margin-bottom: 0px;
  padding: 30px;
  border-bottom: 1px solid #e5daeb;
  font-size: 16px;
  font-weight: 400;
  color: #1e2b4f;
}
.drawer-div [class*=" el-icon-"],
.drawer-div [class^="el-icon-"] {
  color: #6d5efe;
}
</style>

<style lang="less" scoped>
.OrderSearch {
  width: 100%;
  .main {
    // 顶部提示
    .top-tip {
      width: 1030px;
      height: 81px;
      background: #fffbe6;
      border-radius: 8px;
      border: 1px solid #ffc17c;
      position: relative;
      margin-bottom: 20px;
      .top-tip-title {
        height: 30px;
        font-size: 14px;
        font-weight: bold;
        color: #5c2f00;
        line-height: 30px;
        margin-left: 16px;
        margin-top: 13px;
      }
      .top-tip-content {
        height: 17px;
        font-size: 12px;
        font-weight: 400;
        color: #5c2f00;
        line-height: 17px;
        margin-left: 16px;
        margin-top: 2px;
        opacity: 0.4;
      }
      .top-tip-close {
        font-size: 20px;
        color: #5c2f00;
        line-height: 20px;
        position: absolute;
        top: 18px;
        right: 20px;
      }
    }
    // 查询显示
    .order-search-show {
      display: flex;
      flex-direction: column;
      background: #ffffff;
      border-radius: 8px;
      padding: 24px 0px 16px 0px;
      // 搜索条件
      .search-condition-show {
        height: 108px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 0px 24px;
        // 单行显示
        .line-show {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: space-between;
          // 输入框Item
          .input-item {
            width: 230px;
            height: 44px;
          }
          // 时间选择器
          .select-data-div {
            margin-left: -102px;
          }
          // 按钮集合
          .btn-div {
            display: flex;
            flex-direction: row;
            align-items: center;
            .search-btn {
              width: 94px;
              height: 44px;
              background: #ffffff;
              border-radius: 22px;
              border: 1px solid #6d5efe;
              font-size: 14px;
              font-weight: 400;
              color: #6d5efe;
              line-height: 44px;
              text-align: center;
            }
            .merge-btn {
              width: 94px;
              height: 44px;
              background: #ff6400;
              border-radius: 22px;
              font-size: 14px;
              font-weight: 400;
              color: #ffffff;
              line-height: 44px;
              text-align: center;
              margin-left: 12px;
            }
            .export-btn {
              width: 94px;
              height: 44px;
              background: #6d5efe;
              border-radius: 22px;
              font-size: 14px;
              font-weight: 400;
              color: #ffffff;
              line-height: 44px;
              text-align: center;
              margin-left: 12px;
            }
          }
        }
      }
      // 搜索结果
      .search-result-show {
        border-top: 1px solid #e5daeb;
        margin-top: 24px;
        padding: 24px 24px 0px 24px;
        // 身份证选择条件
        .id-card-search-div {
          margin-bottom: 24px;
        }
        // 表格内部样式
        .table-div {
          // 订单号item样式
          .oddlist-item {
            .oddlist-item-text {
              text-align: left;
            }
          }
          .oddlist-item-mid {
            margin-top: 6px;
            margin-bottom: 6px;
          }
          // 收件人
          .recipients-div {
            display: flex;
            flex-direction: column;
            justify-content: center;
            .recipients-tip {
              color: #ff6400;
              margin-top: 6px;
            }
            .recipients-upload {
              width: 56px;
              height: 26px;
              background: #6d5efe;
              border-radius: 4px;
              font-size: 12px;
              font-weight: 400;
              color: #ffffff;
              text-align: center;
              line-height: 26px;
              margin-top: 12px;
              align-self: center;
            }
          }
          // 订单状态
          .state-div {
            .trace-div {
              display: flex;
              flex-direction: row;
              justify-content: center;
              .trace-text {
                height: 20px;
                font-size: 14px;
                font-weight: 400;
                color: #6d5efe;
                line-height: 20px;
                margin-left: 6px;
              }
            }
            .state-div {
              margin-top: 6px;
            }
          }
          // 操作
          .edit-div {
            .edit-text {
              height: 20px;
              font-size: 14px;
              font-weight: 400;
              color: #6d5efe;
              line-height: 20px;
            }
            .face-text {
              margin-bottom: 6px;
            }
          }
        }
        // 订单详情
        .drawer-div {
          .drawer-content {
            padding: 24px;
            //卡片模块
            .card-div {
              display: flex;
              flex-direction: row;
              justify-content: space-between;
              .card-item {
                width: 304px;
                height: 260px;
                background: #ffffff;
                border-radius: 8px;
                border: 1px solid #e5daeb;
                padding: 24px;
                .title {
                  display: flex;
                  flex-direction: row;
                  align-items: center;
                  .icon {
                    width: 3px;
                    height: 12px;
                    background: #6d5efe;
                    border-radius: 2px;
                  }
                  .img-state {
                    margin-left: 25px;
                  }
                  .title-text {
                    height: 20px;
                    font-size: 14px;
                    font-weight: bold;
                    color: #1e2b4f;
                    line-height: 20px;
                    margin-left: 6px;
                    margin-right: 87px;
                  }
                  .status {
                    height: 20px;
                    font-size: 14px;
                    font-weight: 400;
                    color: #ff6400;
                    line-height: 20px;
                    margin-left: 6px;
                  }
                }
                .card-content {
                  margin-top: 22px;
                  //订单信息内容
                  .content-item {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    margin-bottom: 10px;
                    .name {
                      height: 20px;
                      font-size: 14px;
                      font-weight: 400;
                      color: #1e2b4f;
                      line-height: 20px;
                    }
                    .value {
                      height: 20px;
                      font-size: 14px;
                      font-weight: 400;
                      color: #1e2b4f;
                      line-height: 20px;
                    }
                    .account {
                      color: #ff6400;
                    }
                  }
                  //地址信息内容
                  .address-div {
                    margin-bottom: 20px;
                    .tip {
                      height: 20px;
                      font-size: 14px;
                      font-weight: 600;
                      color: #1e2b4f;
                      line-height: 20px;
                    }
                    .value {
                      font-size: 14px;
                      font-weight: 400;
                      color: #1e2b4f;
                      margin-top: 6px;
                    }
                  }
                  //备注
                  .remark-div {
                    font-size: 14px;
                    font-weight: 400;
                    color: #1e2b4f;
                  }
                }
              }
            }
            //物品明细
            .goods-div {
              .title {
                display: flex;
                flex-direction: row;
                align-items: center;
                margin-top: 24px;
                margin-bottom: 22px;
                .icon {
                  width: 3px;
                  height: 12px;
                  background: #6d5efe;
                  border-radius: 2px;
                }
                .title-text {
                  height: 20px;
                  font-size: 14px;
                  font-weight: bold;
                  color: #1e2b4f;
                  line-height: 20px;
                  margin-left: 6px;
                }
              }
            }
            //费用明细
            .cost-div {
              .title {
                display: flex;
                flex-direction: row;
                align-items: center;
                margin-top: 24px;
                margin-bottom: 22px;
                .icon {
                  width: 3px;
                  height: 12px;
                  background: #6d5efe;
                  border-radius: 2px;
                }
                .title-text {
                  height: 20px;
                  font-size: 14px;
                  font-weight: bold;
                  color: #1e2b4f;
                  line-height: 20px;
                  margin-left: 6px;
                }
              }
              .cost-content {
                display: flex;
                flex-direction: row;
                .all-cost-div {
                  width: 200px;
                  height: 172px;
                  background: #f7f7f7;
                  border-radius: 8px;
                  display: flex;
                  flex-direction: column;
                  align-items: center;
                  justify-content: center;
                  .tip {
                    height: 20px;
                    font-size: 14px;
                    font-weight: 400;
                    color: #1e2b4f;
                    line-height: 20px;
                  }
                  .all-cost-text {
                    height: 33px;
                    font-size: 24px;
                    font-weight: bold;
                    color: #ff6400;
                    line-height: 33px;
                  }
                }
                .cost-div {
                  margin-left: 50px;
                  margin-top: 55px;
                  .cost-item {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    margin-bottom: 16px;
                    .name {
                      height: 20px;
                      font-size: 14px;
                      font-weight: 400;
                      color: #1e2b4f;
                      line-height: 20px;
                    }
                    .value {
                      height: 20px;
                      font-size: 14px;
                      font-weight: 400;
                      color: #1e2b4f;
                      line-height: 20px;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
</style>
