<template>
  <div class="my-select" :style="{'--myPadLeft': label.length + 1 + 'em'}">
    <span class="my-label">{{ label }}</span>
    <el-select 
      :placeholder="placeholder"
      v-model="returnValue"
      class="my-select-pad"
      clearable
      popper-class="my-select"
      @change="handleChange">
        <el-option
        v-for="item in options"
        :key="item.id"
        :label="item.name"
        :value="item.id">
      </el-option>
    </el-select>
  </div>
</template>
<script>
export default {
  name: "my-select",
  props: {
    label:{
      type:[String],
      default: ""
    },
    value: {
      type: [String, Number],
      default () {
        return ''
      }
    },
    placeholder:{
      type: [String],
      default: '',
    },
    options:{
      type: [Array],
      default(){
        return []
      }
    }
  },
  data () {
    return {
      returnValue:this.value,
    }
  },

  methods: {
    handleChange (returnValue) {
      this.$emit('input', returnValue)
    },
  }
}
</script>
<style lang="less" scoped>
.my-select{
  position: relative;
  &::v-deep .el-input .el-input__inner{
    border-radius: 10px;
    height:44px;
    padding-left: var(--myPadLeft);
  }
  .my-label{
    position:absolute;
    top: 50%;
    transform:translateY(-50%);
    left: 1em;
    color:#1E2B4F;
    font-size: 14px;
    z-index: 10;
  }
}
</style>