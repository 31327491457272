<template>
  <div class="address-management">
    <div class="main" v-loading="loading">
      <!-- 导航 -->
      <div class="address-nav-div">
        <div
          class="left-nav nav-text posi"
          :class="
            selectedNavType == navType.recipients ? 'nav-text-selected' : ''
          "
          @click="navClick(1)"
        >
          收件人地址
        </div>
        <div
          class="nav-text posi"
          :class="selectedNavType == navType.sender ? 'nav-text-selected' : ''"
          @click="navClick(2)"
        >
          寄件人地址
        </div>
      </div>
      <!-- 面单显示 -->
      <div class="address-show-div">
        <!-- 查询条件 -->
        <div class="search-condition-show">
          <div
            v-if="selectedNavType == navType.recipients"
            class="add-btn posi"
            @click="addRecipientsDialog = true"
          >
            添加收件人地址
          </div>
          <div
            v-if="selectedNavType == navType.sender"
            class="add-btn posi"
            @click="addSenderDialog = true"
          >
            添加寄件人地址
          </div>
          <div class="search-div">
            <el-input
              placeholder="搜索姓名和电话"
              v-model="searchInput"
            ></el-input>
            <div class="search-btn posi" @click="searchBtnClcik">搜索</div>
          </div>
        </div>
        <!-- 搜索结果显示 -->
        <div class="search-result-show">
          <div class="table-div">
            <el-table
              v-if="selectedNavType == navType.recipients"
              :data="recipientsList"
              style="width: 100%"
              border
              empty-text="暂无数据"
            >
              <el-table-column prop="name" label="姓名" :resizable="false">
              </el-table-column>
              <el-table-column prop="phone" label="手机号码" :resizable="false">
              </el-table-column>
              <el-table-column label="省-市-区" width="246" :resizable="false">
                <template slot-scope="scope">
                  <span
                    >{{ scope.row.province }} {{ scope.row.city }}
                    {{ scope.row.county }}
                    <span v-if="scope.row.postCode"
                      >（邮编：{{ scope.row.postCode }}）</span
                    ></span
                  >
                </template>
              </el-table-column>
              <el-table-column
                prop="addressDetail"
                label="详细地址"
                :resizable="false"
              >
              </el-table-column>
              <el-table-column
                prop="identityNumber"
                label="身份证"
                width="216"
                :resizable="false"
              >
                <template slot-scope="scope">
                  <div>{{ scope.row.identityNumber }}</div>
                  <div
                    style="color: #b1b6c3"
                    v-if="
                      scope.row.identityNumber && scope.row.identitySuccess == 1
                    "
                  >
                    校验成功
                  </div>
                  <div
                    style="color: #ff6400"
                    v-if="
                      scope.row.identityNumber && scope.row.identitySuccess == 0
                    "
                  >
                    校验失败
                  </div>
                </template>
              </el-table-column>
              <el-table-column
                prop="defaultFlag"
                label="是否默认"
                width="77"
                :resizable="false"
              >
                <template slot-scope="scope">
                  <span v-if="scope.row.defaultFlag == 1">是</span>
                  <span v-else>否</span>
                </template>
              </el-table-column>
              <el-table-column label="操作" :resizable="false" width="92">
                <template slot-scope="scope">
                  <div class="edit-div">
                    <div
                      class="edit-text posi"
                      @click="recipientEidt(scope.row)"
                    >
                      编辑
                    </div>
                    <div class="space-text posi">｜</div>
                    <div
                      class="edit-text posi"
                      @click.stop="recipientsDelete(scope.$index, scope.row)"
                    >
                      删除
                    </div>
                  </div>
                </template>
              </el-table-column>
            </el-table>
            <el-table
              v-if="selectedNavType == navType.sender"
              :data="senderList"
              style="width: 100%"
              border
              empty-text="暂无数据"
            >
              <el-table-column
                prop="name"
                label="姓名"
                width="90"
                :resizable="false"
              >
              </el-table-column>
              <el-table-column
                prop="phone"
                label="手机号码"
                width="117"
                :resizable="false"
              >
              </el-table-column>
              <el-table-column
                prop="addressOne"
                label="城市"
                width="120"
                :resizable="false"
              >
              </el-table-column>
              <el-table-column
                prop="addressDetail"
                label="具体地址"
                width="360"
                :resizable="false"
              >
                <template slot-scope="scope">
                  <span
                    >{{ scope.row.addressTwo }} {{ scope.row.addressThree }}
                    {{ scope.row.addressFour }}</span
                  >
                </template>
              </el-table-column>
              <el-table-column prop="zipCode" label="邮编" :resizable="false">
              </el-table-column>
              <el-table-column
                prop="defaultFlag"
                label="是否默认"
                width="77"
                :resizable="false"
              >
                <template slot-scope="scope">
                  <span v-if="scope.row.defaultFlag == 1">是</span>
                  <span v-else>否</span>
                </template>
              </el-table-column>
              <el-table-column label="操作" :resizable="false" width="92">
                <template slot-scope="scope">
                  <div class="edit-div">
                    <div class="edit-text posi" @click="senderEidt(scope.row)">
                      编辑
                    </div>
                    <div class="space-text posi">｜</div>
                    <div
                      class="edit-text posi"
                      @click.stop="senderDelete(scope.$index, scope.row)"
                    >
                      删除
                    </div>
                  </div>
                </template>
              </el-table-column>
            </el-table>
          </div>
          <div class="pagination-div">
            <el-pagination
              background
              layout="prev, pager, next"
              :hide-on-single-page="true"
              :total="total"
              :current-page.sync="currentPage"
              :page-size="20"
              @current-change="currentPageChanged"
            >
            </el-pagination>
          </div>
        </div>
      </div>
      <!-- 收件人dialog -->
      <el-dialog
        title="添加收件人地址"
        :visible.sync="addRecipientsDialog"
        :close-on-click-modal="false"
        :close-on-press-escape="false"
        @open="addRecipientsDialogOpen"
        width="1000px"
      >
        <div>
          <el-form
            label-position="top"
            label-width="80px"
            :model="recipientsForm"
            :rules="recipientsRules"
            class="form"
            ref="recipientsAddFormRef"
          >
            <el-form-item label="姓名" prop="name">
              <el-input
                v-model="recipientsForm.name"
                placeholder="姓名"
              ></el-input>
            </el-form-item>
            <el-form-item label="电话号码" prop="phone">
              <el-input
                v-model="recipientsForm.phone"
                placeholder="电话号码"
              ></el-input>
            </el-form-item>
            <el-form-item label="身份证号码" prop="identityNumber">
              <el-input
                v-model="recipientsForm.identityNumber"
                placeholder="身份证号码"
              ></el-input>
            </el-form-item>
            <el-form-item label="地址" class="long-item" prop="retained">
              <el-cascader
                popper-class="myCascader"
                placeholder="地址簿"
                v-model="recipientsForm.retained"
                :options="options"
              >
              </el-cascader>
            </el-form-item>
            <el-form-item
              label="具体街道"
              class="long-item"
              prop="addressDetail"
            >
              <el-input
                v-model="recipientsForm.addressDetail"
                placeholder="具体街道"
              ></el-input>
            </el-form-item>
          </el-form>
          <div class="checkbox-div">
            <el-checkbox v-model="recipientsForm.defaultFlag"
              >设为默认收件人</el-checkbox
            >
          </div>
        </div>
        <span slot="footer" class="dialog-footer">
          <div class="dialog-bottom-btn">
            <div class="canclBtn posi" @click="addRecipientsDialog = false">
              取消
            </div>
            <el-button
              class="sureBtn"
              :loading="dialogLoading"
              @click="recipientsSave"
              >保存</el-button
            >
          </div>
        </span>
      </el-dialog>
      <el-dialog
        title="编辑收件人地址"
        :visible.sync="editRecipientsDialog"
        :close-on-click-modal="false"
        :close-on-press-escape="false"
        width="1000px"
      >
        <div>
          <el-form
            label-position="top"
            label-width="80px"
            :model="recipientsForm"
            :rules="recipientsRules"
            class="form"
            ref="recipientsEditFormRef"
          >
            <el-form-item label="姓名" prop="name">
              <el-input
                v-model="recipientsForm.name"
                placeholder="姓名"
              ></el-input>
            </el-form-item>
            <el-form-item label="电话号码" prop="phone">
              <el-input
                v-model="recipientsForm.phone"
                placeholder="电话号码"
              ></el-input>
            </el-form-item>
            <el-form-item label="身份证号码" prop="identityNumber">
              <el-input
                v-model="recipientsForm.identityNumber"
                placeholder="身份证号码"
              ></el-input>
            </el-form-item>
            <el-form-item label="地址" class="long-item" prop="retained">
              <el-cascader
                popper-class="myCascader"
                placeholder="请选择地址"
                v-model="recipientsForm.retained"
                :key="modelKey"
                :options="options"
                ref="cascaderRefArea"
              >
              </el-cascader>
            </el-form-item>
            <el-form-item
              label="具体街道"
              class="long-item"
              prop="addressDetail"
            >
              <el-input
                v-model="recipientsForm.addressDetail"
                placeholder="具体街道"
              ></el-input>
            </el-form-item>
          </el-form>
          <div class="checkbox-div">
            <el-checkbox v-model="recipientsForm.defaultFlag"
              >设为默认收件人</el-checkbox
            >
          </div>
        </div>
        <span slot="footer" class="dialog-footer">
          <div class="dialog-bottom-btn">
            <div class="canclBtn posi" @click="editRecipientsDialog = false">
              取消
            </div>
            <el-button
              class="sureBtn"
              :loading="dialogLoading"
              @click="recipientsEditSave"
              >保存</el-button
            >
          </div>
        </span>
      </el-dialog>
      <!-- 寄件人dialog -->
      <el-dialog
        title="添加寄件人地址"
        :visible.sync="addSenderDialog"
        :close-on-click-modal="false"
        :close-on-press-escape="false"
        @open="addSenderDialogOpen"
        width="1000px"
      >
        <div>
          <el-form
            label-position="top"
            label-width="80px"
            :model="senderForm"
            :rules="senderRules"
            class="form"
            ref="senderAddFormRef"
          >
            <el-form-item label="名称" prop="name">
              <el-input v-model="senderForm.name" placeholder="姓名"></el-input>
            </el-form-item>
            <el-form-item label="电话号码" prop="phone">
              <el-input
                v-model="senderForm.phone"
                placeholder="电话号码"
              ></el-input>
            </el-form-item>
            <el-form-item label="邮编" prop="postCode" class="zipCode">
              <el-input v-model="senderForm.postCode" placeholder="邮编">
                <el-button slot="append" @click="queryClicked"
                  >查询地址</el-button
                >
              </el-input>
              <el-cascader-panel
                :options="zipCodeOptions"
                v-if="zipCodeOptions.length != 0"
                @change="getNodes"
              ></el-cascader-panel>
            </el-form-item>
            <el-form-item label="城市" class="address" required>
              <el-form-item prop="addressOne">
                <el-input
                  v-model="senderForm.addressOne"
                  style="margin-right: 20px"
                  placeholder="城市"
                ></el-input>
              </el-form-item>
              <el-form-item prop="addressTwo">
                <el-input
                  v-model="senderForm.addressTwo"
                  style="margin-right: 20px"
                  placeholder="地址1"
                ></el-input>
              </el-form-item>
              <el-input
                v-model="senderForm.addressThree"
                placeholder="地址2"
              ></el-input>
              <el-input
                v-model="senderForm.addressFour"
                placeholder="地址3"
              ></el-input>
            </el-form-item>
          </el-form>
          <div class="checkbox-div">
            <el-checkbox v-model="senderForm.defaultFlag"
              >设为默认寄件人</el-checkbox
            >
          </div>
        </div>
        <span slot="footer" class="dialog-footer">
          <div class="dialog-bottom-btn">
            <div class="canclBtn posi" @click="addSenderDialog = false">
              取消
            </div>
            <el-button
              class="sureBtn"
              :loading="dialogLoading"
              @click="senderSave"
              >保存</el-button
            >
          </div>
        </span>
      </el-dialog>
      <el-dialog
        title="编辑寄件人地址"
        :visible.sync="editSenderDialog"
        :close-on-click-modal="false"
        :close-on-press-escape="false"
        width="1000px"
      >
        <div>
          <el-form
            label-position="top"
            label-width="80px"
            :model="senderForm"
            :rules="senderRules"
            class="form"
            ref="senderEditFormRef"
          >
            <el-form-item label="名称" prop="name">
              <el-input v-model="senderForm.name" placeholder="姓名"></el-input>
            </el-form-item>
            <el-form-item label="电话号码" prop="phone">
              <el-input
                v-model="senderForm.phone"
                placeholder="电话号码"
              ></el-input>
            </el-form-item>
            <el-form-item label="邮编" prop="postCode" class="zipCode">
              <el-input
                v-model="senderForm.postCode"
                placeholder="邮编"
                class="zipCode"
              >
                <el-button slot="append" @click="queryClicked"
                  >查询地址</el-button
                >
              </el-input>
              <el-cascader-panel
                :options="zipCodeOptions"
                v-if="zipCodeOptions.length != 0"
                @change="getNodes"
              ></el-cascader-panel>
            </el-form-item>
            <!-- <div class="query-btn posi"  @click="queryClicked">查询地址</div> -->
            <el-form-item label="城市" class="address" required>
              <el-form-item prop="addressOne">
                <el-input
                  v-model="senderForm.addressOne"
                  style="margin-right: 20px"
                  placeholder="城市"
                ></el-input>
              </el-form-item>
              <el-form-item prop="addressTwo">
                <el-input
                  v-model="senderForm.addressTwo"
                  style="margin-right: 20px"
                  placeholder="地址1"
                ></el-input>
              </el-form-item>
              <el-input
                v-model="senderForm.addressThree"
                placeholder="地址2"
              ></el-input>
              <el-input
                v-model="senderForm.addressFour"
                placeholder="地址3"
              ></el-input>
            </el-form-item>
          </el-form>
          <div class="checkbox-div">
            <el-checkbox v-model="senderForm.defaultFlag"
              >设为默认寄件人</el-checkbox
            >
          </div>
        </div>
        <span slot="footer" class="dialog-footer">
          <div class="dialog-bottom-btn">
            <div class="canclBtn posi" @click="editSenderDialog = false">
              取消
            </div>
            <el-button
              class="sureBtn"
              :loading="dialogLoading"
              @click="senderEditSave"
              >保存</el-button
            >
          </div>
        </span>
      </el-dialog>
    </div>
  </div>
</template>
<script>
import {
  getChinaCityList,
  addConsigneeAddress,
  listConsigneeAddress,
  delConsigneeAddress,
  updateConsigneeAddress,
} from "@/api/address/consignee/consignee";
import {
  listSenderAddress,
  uploadIdentityImage,
  delSenderAddress,
  updateSenderAddress,
  getInfoByPostcode,
} from "@/api/address/sender/sender";
// import Vue from "vue";
export default {
  name: "AddressPool",
  data() {
    // 校验只能输入中文
    var nameReg = /^[\u4e00-\u9fa5]+$/;
    var validateName = (rule, value, callback) => {
      if (!value) {
        callback(new Error("请输入姓名"));
      }
      if (!nameReg.test(value)) {
        callback(new Error("只可输入中文"));
      } else {
        callback();
      }
    };
    var IdNumberReg =
      /^(^[1-9]\d{7}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])\d{3}$)|(^[1-9]\d{5}[1-9]\d{3}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])((\d{4})|\d{3}[Xx])$)$/;
    var validateIdNumber = (rule, value, callback) => {
      if (!value) {
        callback();
      } else {
        if (!IdNumberReg.test(value)) {
          callback(new Error("请输入正确的身份证号"));
        } else {
          callback();
        }
      }
    };
    // 此处自定义校验英国邮编js逻辑
    var zipCodeReg =
      /^([Gg][Ii][Rr] 0[Aa]{2})|((([A-Za-z][0-9]{1,2})|(([A-Za-z][A-Ha-hJ-Yj-y][0-9]{1,2})|(([A-Za-z][0-9][A-Za-z])|([A-Za-z][A-Ha-hJ-Yj-y][0-9]?[A-Za-z]))))\s?[0-9][A-Za-z]{2})$/;
    var validatePhone = (rule, value, callback) => {
      if (!zipCodeReg.test(value)) {
        callback(new Error("请输入英国邮编"));
      } else {
        callback();
      }
    };
    // 校验只能输入英文空格数字 验证地址
    var addressReg = /^[a-z0-9\s]+$/i;
    var validateAddress = (rule, value, callback) => {
      if (!addressReg.test(value)) {
        callback(new Error("请输入英文"));
      } else {
        callback();
      }
    };
    // 校验只能输入英文空格 验证名字
    var nameRegSender = /^[a-z\s]+$/i;
    var validateNameSender = (rule, value, callback) => {
      if (!nameRegSender.test(value)) {
        callback(new Error("请输入英文"));
      } else {
        callback();
      }
    };
    return {
      // 整体loading
      loading: false,
      // dialog loading
      dialogLoading: false,
      // 保存收件人的弹窗
      addRecipientsDialog: false,
      // 编辑收件人弹窗
      editRecipientsDialog: false,
      // 保存寄件人的弹窗
      addSenderDialog: false,
      // 修改寄件人弹窗
      editSenderDialog: false,
      //导航类型
      navType: {
        recipients: 1,
        sender: 2,
      },
      //选择的导航类型
      selectedNavType: 1,
      //搜素的条件
      searchInput: "",
      //收件人列表
      recipientsList: [
        {
          id: "",
          name: "易洋千玺", // 收件人姓名
          identityNumber: "342524199412124567", // 身份证号
          identitySuccess: 1, //身份证是否验证成功
          phone: "13876889647", // 收件人手机号
          province: "浙江", // 省
          city: "杭州市", // 市
          county: "上城区", // 区
          postCode: "310002", // 邮编
          addressDetail: "文三路123号", // 详细地址
          retained: ["zhinan", "shejiyuanze", "yizhi"], // 三级联动值
          defaultFlag: 0,
        },
      ],
      //寄件人列表
      senderList: [
        {
          id: "",
          name: "JIANG",
          phone: "07588988866",
          zipCode: "CV1 2UD",
          addressOne: "COVENTRY",
          addressTwo: "34 RODYARDway",
          addressThree: "CV1 2UD",
          addressFour: "UK",
          defaultFlag: 0,
        },
      ],
      // 寄件人邮编查询结果数组
      zipCodeOptions: [],
      // 数据总数
      total: 100,
      // 当前页码
      currentPage: 1,
      // 收件人信息
      modelKey: 0,
      recipientsForm: {
        name: "战胜德国", // 收件人姓名
        identityNumber: "342623199411201439", // 身份证号
        phone: "15622903456", // 收件人手机号
        province: "", // 省
        city: "", // 市
        county: "", // 区
        postCode: "", // 邮编
        addressDetail: "", // 详细地址
        retained: [0, 0, 2], // 三级联动值
        defaultFlag: false,
      },
      recipientsFormDefault: {
        name: "", // 收件人姓名
        identityNumber: "", // 身份证号
        phone: "", // 收件人手机号
        province: "", // 省
        city: "", // 市
        county: "", // 区
        postCode: "", // 邮编
        addressDetail: "", // 详细地址
        retained: [0, 0, 2], // 三级联动值
        defaultFlag: false,
      },
      // 全国地址
      options: [],
      // 收件人rules
      recipientsRules: {
        name: { required: true, validator: validateName, trigger: "change" },
        phone: {
          required: true,
          message: "请输入正确的电话号码",
          min: 11,
          max: 11,
          trigger: "change",
        },
        identityNumber: {
          required: false,
          validator: validateIdNumber,
          trigger: "change",
        },
        retained: { required: true, message: "请选择城市", trigger: "change" },
        addressDetail: {
          required: true,
          message: "请输入街道地址",
          trigger: "change",
        },
      },
      // 寄件人信息
      senderForm: {
        name: "JIANG",
        phone: "07588988866",
        zipCode: "CV1 2UD",
        addressOne: "COVENTRY",
        addressTwo: "34 RODYARDway",
        addressThree: "CV1 2UD",
        addressFour: "UK",
        defaultFlag: false,
      },
      senderFormDefault: {
        name: "",
        phone: "",
        postCode: "",
        addressOne: "",
        addressTwo: "",
        addressThree: "",
        addressFour: "",
        defaultFlag: false,
      },
      // 寄件人rules
      senderRules: {
        name: [
          { required: true, validator: validateNameSender, trigger: "change" },
        ],
        phone: [
          {
            required: true,
            message: "请输入正确的电话号码",
            min: 11,
            max: 11,
            trigger: "change",
          },
        ],
        postCode: [
          { required: true, validator: validatePhone, trigger: "change" },
        ],
        addressOne: [
          { required: true, validator: validateAddress, trigger: "change" },
        ],
        addressTwo: [
          { required: true, validator: validateAddress, trigger: "change" },
        ],
      },
    };
  },
  watch: {},
  mounted() {
    console.log("进入面单管理");
    //请求数据
    this.getOption();
    this.getList();
  },
  methods: {
    // 选择邮编所在地址
    getNodes(row) {
      console.log("row", row);
      this.senderForm.addressOne = row[0].town;
      this.senderForm.addressTwo = row[0].line_1;
      this.senderForm.addressThree = row[0].line_2;
      this.senderForm.addressFour = row[0].line_3;
      this.zipCodeOptions = [];
    },
    //邮编查询
    getInfoByPostcode() {
      let updata = {
        postcode: this.senderForm.postCode,
      };
      getInfoByPostcode(updata).then((res) => {
        if (res.data.code === 0) {
          // this.zipCodeOptions
          let data = res.data.data.info;
          // 所有的路线数组
          let delivery_points = data.delivery_points;
          for (let i = 0; i < delivery_points.length; i++) {
            let item = delivery_points[i];
            let obj = {
              label: `${item.line_1 ? item.line_1 + "," : ""}${
                item.line_2 ? item.line_2 + "," : ""
              }${item.line_3 ? item.line_3 + "," : ""}${data.town}`,
              value: {
                town: data.town,
                line_1: item.line_1,
                line_2: item.line_2,
                line_3: item.line_3,
              },
            };
            this.zipCodeOptions.push(obj);
          }
        }
      });
    },
    // 导航按钮被点击
    navClick(arg) {
      //切换了不同的导航
      if (arg != this.selectedNavType) {
        console.log("切换了导航");
        //清空状态
        this.searchInput = "";
        this.currentPage = 1;
        //切换导航
        this.selectedNavType = arg;
        //请求数据
        this.getList();
      }
    },
    // 搜索按钮被点击
    searchBtnClcik() {
      //请求数据
      this.getList(this.searchInput);
    },
    //  页码切换
    currentPageChanged() {
      //请求数据
      this.getList(this.searchInput);
    },
    // 获取地址列表
    getOption() {
      getChinaCityList().then((res) => {
        if (
          res.data &&
          res.data.code == 0 &&
          res.data.data &&
          res.data.data.list
        ) {
          this.options = res.data.data.list;
        }
      });
    },
    // 获取表格数据
    getList(searchData) {
      //收件人
      if (this.selectedNavType == this.navType.recipients) {
        this.getRecipientsList(searchData);
      } else {
        this.getSenderList(searchData);
      }
    },
    //  查询收件人
    async getRecipientsList(searchData) {
      let data = {
        nameOrPhone: searchData,
        pageNum: this.currentPage,
        pageSize: 20,
      };
      this.loading = true;
      await listConsigneeAddress(data).then((res) => {
        if (
          res.data &&
          res.data.code == 0 &&
          res.data.data &&
          res.data.data.list
        ) {
          let data = res.data.data.list;
          this.currentPage = data.currPage;
          this.total = data.totalCount;
          let list = res.data.data.list.list;
          list.forEach((item) => {
            item.retained = [
              item.province,
              item.city,
              item.county + " " + "(邮编:" + item.postCode + ")",
            ];
          });
          this.recipientsList = list;
        }
        this.loading = false;
      });
    },
    // 保存收件人
    recipientsSave() {
      this.$refs.recipientsAddFormRef.validate((valid) => {
        if (valid) {
          this.dialogLoading = true;
          let data = {
            addressDetail: this.recipientsForm.addressDetail,
            province: this.recipientsForm.retained[0],
            city: this.recipientsForm.retained[1],
            county: this.recipientsForm.retained[2].split(" ")[0],
            // postCode: this.recipientsForm.retained[2].split(' ')[1].split(':')[1].split(')')[0],
            identityNumber: this.recipientsForm.identityNumber,
            name: this.recipientsForm.name,
            phone: this.recipientsForm.phone,
            defaultFlag: this.recipientsForm.defaultFlag ? 1 : 0,
          };
          addConsigneeAddress(data)
            .then((res) => {
              if (res.data.code == 0) {
                this.addRecipientsDialog = false;
                this.getList(this.searchInput);
              }
              this.dialogLoading = false;
            })
            .catch(() => {
              this.dialogLoading = false;
            });
        } else {
          return;
        }
      });
    },
    // 保存编辑的收件人
    recipientsEditSave() {
      this.$refs.recipientsEditFormRef.validate((valid) => {
        if (valid) {
          this.dialogLoading = true;
          let data = {
            id: this.recipientsForm.id,
            addressDetail: this.recipientsForm.addressDetail,
            province: this.recipientsForm.retained[0],
            city: this.recipientsForm.retained[1],
            county: this.recipientsForm.retained[2].split(" ")[0],
            // postCode: this.recipientsForm.retained[2].split(' ')[1].split(':')[1].split(')')[0],
            identityNumber: this.recipientsForm.identityNumber,
            name: this.recipientsForm.name,
            phone: this.recipientsForm.phone,
            defaultFlag: this.recipientsForm.defaultFlag ? 1 : 0,
          };
          updateConsigneeAddress(data).then((res) => {
            if (res.data.code == 0) {
              this.editRecipientsDialog = false;
              this.getList(this.searchInput);
            }
            this.dialogLoading = false;
          });
        } else {
          return;
        }
      });
    },
    // 编辑收件人被点击
    recipientEidt(row) {
      // todo
      let data = { ...row };
      data.defaultFlag = row.defaultFlag == 1 ? true : false;
      data.retained = [row.province, row.city, row.county];
      this.recipientsForm = data;
      this.modelKey++;
      this.editRecipientsDialog = true;

      // });
    },
    // 删除收件人
    recipientsDelete(index, row) {
      delConsigneeAddress({ addressId: row.id }).then((res) => {
        if (res.data && res.data.code == 0) {
          //  this.recipientsList.splice(index,1);
          this.getList(this.searchInput);
        }
      });
    },
    // 查询寄件人
    async getSenderList(searchData) {
      let data = {
        nameOrPhone: searchData,
        pageNum: this.currentPage,
        pageSize: 20,
      };
      this.loading = true;
      await listSenderAddress(data).then((res) => {
        if (
          res.data &&
          res.data.code == 0 &&
          res.data.data &&
          res.data.data.list
        ) {
          let data = res.data.data.list;
          this.currentPage = data.currPage;
          this.total = data.totalCount;
          let list = res.data.data.list.list;
          this.senderList = list;
        }
        this.loading = false;
      });
    },
    // 保存新增的寄件人
    senderSave() {
      this.$refs.senderAddFormRef.validate((valid) => {
        if (valid) {
          this.dialogLoading = true;
          let data = { ...this.senderForm };
          data.zipCode = this.senderForm.postCode;
          data.defaultFlag = this.senderForm.defaultFlag ? 1 : 0;
          uploadIdentityImage(data).then((res) => {
            if (res.data.code == 0) {
              this.addSenderDialog = false;
              this.getList(this.searchInput);
            }
            this.dialogLoading = false;
          });
        } else {
          return;
        }
      });
    },
    // 删除寄件人
    senderDelete(index, row) {
      delSenderAddress({ addressId: row.id }).then((res) => {
        if (res.data && res.data.code == 0) {
          //  this.senderList.splice(index,1);
          this.getList(this.searchInput);
        }
      });
    },
    // 编辑寄件人被点击
    senderEidt(row) {
      let data = { ...row };
      data.postCode = row.zipCode;
      data.defaultFlag = row.defaultFlag == 1 ? true : false;
      this.senderForm = data;
      this.editSenderDialog = true;
    },
    // 保存编辑的寄件人
    senderEditSave() {
      this.$refs.senderEditFormRef.validate((valid) => {
        if (valid) {
          this.dialogLoading = true;
          let data = { ...this.senderForm };
          data.defaultFlag = this.senderForm.defaultFlag ? 1 : 0;
          updateSenderAddress(data)
            .then((res) => {
              if (res.data.code == 0) {
                this.editSenderDialog = false;
                this.getList(this.searchInput);
              }
              this.dialogLoading = false;
            })
            .catch(() => {
              this.dialogLoading = false;
            });
        } else {
          return;
        }
      });
    },
    // 打开新增收件人弹窗
    addRecipientsDialogOpen() {
      let _this = this;
      this.$nextTick(() => {
        _this.recipientsForm = { ..._this.recipientsFormDefault };
        _this.$refs.recipientsAddFormRef.resetFields();
      });
    },
    // 打开新增寄件人弹窗
    addSenderDialogOpen() {
      let _this = this;
      this.$nextTick(() => {
        _this.senderForm = { ..._this.senderFormDefault };
        _this.$refs.senderAddFormRef.resetFields();
      });
    },
    //点击查询
    queryClicked() {
      console.log("查询按钮被点击");
      this.getInfoByPostcode();
    },
  },
};
</script>
<style></style>
<style>
.address-management .el-dialog__body {
  padding: 20px;
}
.address-management .el-form {
  display: flex;
  flex-wrap: wrap;
}
.address-management .el-form--label-top .el-form-item__label {
  padding: 0px;
}
.address-management .el-dialog__wrapper .el-input {
  width: 220px;
  margin-right: 20px;
}
.address-management .el-dialog__wrapper .el-input__inner {
  height: 44px;
  border-radius: 10px;
}
.address-management .el-dialog__wrapper .long-item .el-input {
  width: 460px;
}
.address-management .address .el-form-item__content {
  display: flex;
}
.myCascader .el-cascader-node.in-active-path,
.myCascader .el-cascader-node.is-active,
.myCascader .el-cascader-node.is-selectable.in-checked-path {
  color: #6d5efe;
}
</style>
<style lang="less" scoped>
.address-management {
  width: 100%;
  .zipCode {
    &::v-deep .el-input__inner {
      border-radius: 8px;
      border-bottom-right-radius: 0;
      border-top-right-radius: 0;
    }
    &::v-deep .el-input-group__append {
      border-top-right-radius: 8px;
      border-bottom-right-radius: 8px;
      background: #6d5efe;
      color: #fff;
    }
    position: relative;
    //  下拉框
    &::v-deep .el-cascader-panel {
      position: absolute;
      z-index: 10;
      width: 100%;
      background: #fff;
      .el-cascader-menu {
        width: 100%;
      }
    }
  }
  .main {
    background: #ffffff;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    // 导航条
    .address-nav-div {
      display: flex;
      flex-direction: row;
      align-items: center;
      height: 68px;
      border-bottom: 1px solid #e5daeb;
      .nav-text {
        height: 20px;
        font-size: 14px;
        font-weight: 400;
        color: #1e2b4f;
        line-height: 20px;
      }
      .nav-text-selected {
        font-weight: bold;
        color: #6d5efe;
      }
      .left-nav {
        margin-left: 33px;
        margin-right: 46px;
      }
    }
    // 显示区域
    .address-show-div {
      display: flex;
      flex-direction: column;
      padding: 24px;
      // 搜索条件
      .search-condition-show {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        // 新增按钮
        .add-btn {
          width: 146px;
          height: 44px;
          background: #6d5efe;
          border-radius: 22px;
          font-size: 14px;
          font-weight: 400;
          color: #ffffff;
          line-height: 44px;
          text-align: center;
        }
        // 搜索框
        .search-div {
          display: flex;
          flex-direction: row;
          ::v-deep .el-input {
            width: 230px;
            margin-right: 20px;
            .el-input__inner {
              border-radius: 10px;
              border: 1px solid #d2d5dc;
              height: 44px;
            }
          }
          .search-btn {
            width: 94px;
            height: 44px;
            background: #ffffff;
            border-radius: 22px;
            border: 1px solid #6d5efe;
            font-size: 14px;
            font-weight: 400;
            color: #6d5efe;
            line-height: 44px;
            text-align: center;
          }
        }
      }
      // 结果展示
      .search-result-show {
        margin-top: 24px;
        .edit-div {
          display: flex;
          flex-direction: row;
          justify-content: center;
          .edit-text {
            height: 20px;
            font-size: 14px;
            font-weight: 400;
            color: #6d5efe;
            line-height: 20px;
          }
          .space-text {
            height: 20px;
            font-size: 14px;
            font-weight: 400;
            color: #b1b6c3;
            line-height: 20px;
          }
        }
      }
    }
    .query-btn {
      margin-top: 40px;
      width: 94px;
      height: 43px;
      background: #6d5efe;
      border-radius: 10px;
      font-size: 14px;
      font-weight: 400;
      color: #ffffff;
      line-height: 43px;
      text-align: center;
      margin-left: 20px;
    }
    .dialog-bottom-btn {
      display: flex;
      align-items: center;
      justify-content: center;
      .canclBtn {
        width: 100px;
        height: 44px;
        background: #ffffff;
        border-radius: 22px;
        border: 1px solid #d2d5dc;
        font-size: 16px;
        font-weight: 400;
        color: #636d88;
        line-height: 44px;
        text-align: center;
        margin-right: 20px;
      }
      .sureBtn {
        width: 100px;
        height: 44px;
        background: #6d5efe;
        border-radius: 22px;
        font-size: 16px;
        font-weight: bold;
        color: #ffffff;
        // line-height: 44px;
        text-align: center;
      }
    }
  }
}
</style>
