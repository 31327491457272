<template>
  <div class="surfaceSingle-management">
    <div class="main">
      <!-- 导航 -->
      <div class="surfaceSingle-nav-div">
        <div
          class="not-print-nav surfaceSingle-nav-text posi"
          :class="
            selectedNavType == navType.notPrint
              ? 'surfaceSingle-nav-text-selected'
              : ''
          "
          @click="navClick(0)"
        >
          尚未打印
        </div>
        <div
          class="surfaceSingle-nav-text posi"
          :class="
            selectedNavType == navType.havePrint
              ? 'surfaceSingle-nav-text-selected'
              : ''
          "
          @click="navClick(1)"
        >
          已打印
        </div>
      </div>
      <!-- 面单显示 -->
      <div class="surfaceSingle-show-div" v-loading="loading">
        <!-- 查询条件 -->
        <div class="search-condition-show">
          <!-- 条件第一排 -->
          <div class="line-show">
            <!-- 收件人 -->
            <div class="input-item">
              <MyInput :placeholder='"请输入"' :label='"收件人："' v-model="searchCondition.name"></MyInput>
            </div>
            <!-- 收件人电话 -->
            <div class="input-item">
              <MyInput :placeholder='"请输入"' :label='"收件人电话："' v-model="searchCondition.phone" @input="(v) => (searchCondition.phone = v.replace(/[^\d]/g, ''))"></MyInput>
            </div>
            <!-- 单号 -->
            <div class="input-item">
              <MyInput :placeholder='"请输入"' :label='"单号："' v-model="searchCondition.number"></MyInput>
            </div>
            <!-- 服务线路 -->
            <div class="input-item">
              <Myselect  :label="'服务：'" v-model="searchCondition.serve" :options="wayPath"></Myselect>
            </div>
          </div>
          <!-- 条件第二排 -->
          <div class="line-show">
            <!-- 时间选择 -->
            <div class="select-data-div">
              <el-date-picker
                popper-class="date-picker"
                v-model="searchCondition.date"
                type="daterange"
                :editable="false"
                range-separator="至"
                start-placeholder="开始时间"
                end-placeholder="结束时间"
              >
              </el-date-picker>
            </div>
            <!-- 操作按钮 -->
            <div class="btn-div">
              <div class="search-btn posi" @click="getFace">搜索</div>
              <div class="merge-btn posi" @click="mergeBtnClick">合并面单</div>
              <div
                v-if="selectedNavType == navType.notPrint"
                class="sign-btn posi"
                @click="signBtnClick"
              >
                标记为已打印
              </div>
            </div>
          </div>
        </div>
        <!-- 搜索结果显示 -->
        <div class="search-result-show">
          <div class="table-div">
            <el-table
            :data="seachShowData"
            style="width: 100%"
            border
            empty-text="暂无数据"
            @selection-change="handleSelectionChange"
            >
              <el-table-column type="selection" width="51" :resizable="false"> </el-table-column>
              <el-table-column prop="lineName" label="服务路线" width="92" :resizable="false">
              </el-table-column>
              <el-table-column prop="faceNum" label="单号" width="191" :resizable="false">
              </el-table-column>
              <el-table-column prop="senderName" label="寄件人" width="98" :resizable="false">
              </el-table-column>
              <el-table-column prop="consigneeName" label="收件人" width="88" :resizable="false">
              </el-table-column>
              <el-table-column prop="destination" label="目的地" width="153" :resizable="false">
              </el-table-column>
              <!-- <el-table-column prop="weight" label="申报重量(KG)" width="88" :resizable="false">
              </el-table-column> -->
              <el-table-column prop="amount" label="金额(£)" width="101" :resizable="false">
              </el-table-column>
              <el-table-column prop="logisticsStatus" label="状态" width="82" :resizable="false">
              </el-table-column>
              <el-table-column label="操作" :resizable="false">
                <template slot-scope="scope">
                  <div
                    class="result-item-edit"
                    :class="
                      scope.row.couldViewFace == '0' ? 'result-item-edit-hand' : ''
                    "
                    @click="handleEdit(scope.$index, scope.row)"
                  >
                    查看面单
                  </div>
                </template>
              </el-table-column>
            </el-table>
          </div>
          <div class="pagination-div">
            <!-- <el-pagination
              background
              layout="prev, pager, next"
              :hide-on-single-page="true"
              :page-count="pageCount"
              :current-page.sync="currentPage"
              @current-change="currentPageChanged"
            >
            </el-pagination> -->
            <Pagination
              :total="pageCount"
              :page.sync="currentPage"
              :limit.sync="pageSize"
              @pagination="getFace"
            />
          </div>
        </div>
      </div>
    </div>
    
  </div>
</template>
<script>
import MyInput from "../../components/input.vue";
import Myselect from "../../components/select.vue";
import { listFace } from '@/api/user/user'
import { updateFacePrintStatus,showFaceSheetById, showMergeFaceSheetByIds } from '@/api/user/user'
import { getAllServiceLine } from '@/api/order/order'
import Pagination from "@/components/pagination.vue";
export default {
  name: "surfaceSingleManagement",
  components:{
    MyInput,
    Myselect,
    Pagination,
  },
  data() {
    return {
      //导航类型
      navType: {
        notPrint: 0,
        havePrint: 1,
      },
      //线路类型
      wayPath: [
        {
          value: 0,
          label: "全部",
        },
        {
          value: 1,
          label: "ParcelForce经济包",
        },
        {
          value: 2,
          label: "两罐奶粉",
        },
      ],
      //选择的导航类型
      selectedNavType: 0,
      //搜索条件
      searchCondition: {
        name: "",
        phone: "",
        number: "",
        serve: "",
        date: "",
      },
      //搜索的结果
      seachShowData: [
      ],
      // 选择的面单单
      multipleSelection: [],
      // 分页数据
      pageCount: 0,
      // 当前页码
      currentPage: 1,
      pageSize: 50,
      // 表格数据加载中
      loading: false,
      //面单id
      faceid: 0,
    };
  },
  watch: {
  },
  mounted(){
    console.log("进入面单管理")
    //请求数据
    this.getFace();
    this.getAllServiceLine();
  },
  methods: {
    //获取面单数据
    getFace(){
      let upData = {
        consigneeName: this.searchCondition.name,
        consigneePhone: this.searchCondition.phone,
        endTime: this.searchCondition.date[1],
        lineId: this.searchCondition.serve,
        orderNum: this.searchCondition.number,
        pageNum: this.currentPage,
        pageSize: this.pageSize,
        printStatus:this.selectedNavType,
        startTime: this.searchCondition.date[0]
      }
      listFace(upData).then(res => {
        if(res.data.code === 0) {
          this.seachShowData = res.data.data.list.list;
          this.pageCount = res.data.data.list.totalCount;
        }
        console.log(res, '面单接口返回值')
      })
    },
    // 获取服务路线分类
    getAllServiceLine(){
      getAllServiceLine().then(res => {
        if(res.data.code === 0){
          this.wayPath = res.data.data.list;
        }
        console.log(res, "服务路线接口返回值",this.wayPath);
      })
    },
    updateFacePrintStatus(printArr){
      if(printArr.length == 0){
        this.$message.error("请选择需要标记的面单");
        return;
      }
      let upData = {
        ids:printArr
      }
      //标记为已打印
      updateFacePrintStatus(upData).then(res=> {
        if(res.data.code ===0){
          this.$message.success(res.data.message)
          this.getFace()
        }else{
          this.$message.error(res.data.message)
        }

      });
    },
    //查看面单
    showFaceSheetById(id){
      let upData = {
        id :id,
      }
      showFaceSheetById(upData).then(res => {
        if(res.data.code === 0){
          window.open(res.data.data.path, '_blank');
        }
      })
    },
     //合并面单
    showMergeFaceSheetByIds(ids){
      if(ids.length == 0){
        this.$message.error("请选择需要合并的面单");
        return;
      }
      showMergeFaceSheetByIds(ids).then(res => {
        if(res.data.code === 0){
          window.open(res.data.data.path, '_blank');
        }
        console.log(res, "合并面单");
      })
    },
    // 导航按钮被点击
    navClick(arg) {
      //切换了不同的导航
      if (arg != this.selectedNavType) {
        console.log("切换了导航")
        //清空状态
        this.searchCondition.name = "";
        this.searchCondition.phone = "";
        this.searchCondition.phone = "";
        this.searchCondition.serve = 0;
        this.searchCondition.date = "";
        this.currentPage = 1;
        //请求数据
      }
      this.selectedNavType = arg;
      this.getFace();
    },
    // 搜索按钮被点击
    searchBtnClcik() {
      console.log("搜索按钮被点击");
      //请求数据
    },
    // 合并面单按钮被点击
    mergeBtnClick() {
      console.log("合并面单按钮被点击");
      let signArr = this.multipleSelection
      let upDataArr = []
      for(let i=0; i<signArr.length; i++){
        upDataArr.push(signArr[i].id)
      }
      this.showMergeFaceSheetByIds(upDataArr);
    },
    signBtnClick() {
      console.log("标记为已打印按钮被点击");
      let signArr = this.multipleSelection
      let upDataArr = []
      for(let i=0; i<signArr.length; i++){
        upDataArr.push(signArr[i].id)
      }
      this.updateFacePrintStatus(upDataArr)
      // console.log(upDataArr, 'upDataArr')
    },
    // 查看面单被点击
    handleEdit(arg1, arg2) {
      console.log("查看面单被点击", arg2);
      if(arg2.couldViewFace == 0){
        return;
      }
      this.faceid = arg2.id;
      this.showFaceSheetById(arg2.id);
    },
    // 选择的面单发生变化
    handleSelectionChange(args) {
      console.log("选择的面单列表发生变化", args);
      this.multipleSelection = args;
    },
    //
    currentPageChanged(arg){
      console.log("切换了页码",arg);
      //请求数据
      this.getFace();
    }
  },
};
</script>
<style>
</style>
<style lang="less" scoped>
.surfaceSingle-management {
  width: 100%;
  .main {
    background: #ffffff;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    // 导航条
    .surfaceSingle-nav-div {
      display: flex;
      flex-direction: row;
      align-items: center;
      height: 68px;
      border-bottom: 1px solid #e5daeb;
      .surfaceSingle-nav-text {
        height: 20px;
        font-size: 14px;
        font-weight: 400;
        color: #1e2b4f;
        line-height: 20px;
      }
      .surfaceSingle-nav-text-selected {
        font-weight: bold;
        color: #6d5efe;
      }
      .not-print-nav {
        margin-left: 40px;
        margin-right: 60px;
      }
    }
    // 显示区域
    .surfaceSingle-show-div {
      display: flex;
      flex-direction: column;
      padding: 24px;
      // 搜索条件
      .search-condition-show {
        height: 108px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        // 单行显示
        .line-show {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: space-between;
          // 输入框Item
          .input-item {
            width: 230px;
            height: 44px;
          }
          // 按钮集合
          .btn-div {
            display: flex;
            flex-direction: row;
            align-items: center;
            .search-btn {
              width: 94px;
              height: 44px;
              background: #ffffff;
              border-radius: 22px;
              border: 1px solid #6d5efe;
              font-size: 14px;
              font-weight: 400;
              color: #6d5efe;
              line-height: 44px;
              text-align: center;
            }
            .merge-btn {
              width: 94px;
              height: 44px;
              background: #ff6400;
              border-radius: 22px;
              font-size: 14px;
              font-weight: 400;
              color: #ffffff;
              line-height: 44px;
              text-align: center;
              margin-left: 12px;
            }
            .sign-btn {
              width: 120px;
              height: 44px;
              background: #6d5efe;
              border-radius: 22px;
              font-size: 14px;
              font-weight: 400;
              color: #ffffff;
              line-height: 44px;
              text-align: center;
              margin-left: 12px;
            }
          }
        }
      }
      // 结果展示
      .search-result-show {
        margin-top: 24px;
        .result-item-edit {
          height: 20px;
          font-size: 14px;
          font-weight: 400;
          color: #6d5efe;
          cursor: pointer;
        }
        .result-item-edit-hand {
          cursor: not-allowed;
          color: #b1b6c3;
        }
      }
    }
  }
}
</style>