<template>
  <div class="OrderSearch">
    <div class="main">
      <!-- 查询显示区域 -->
      <div class="order-search-show">
       
        <!-- 查询结果 -->
        <div class="search-result-show">
         
          <!-- 搜索结果 -->
          <div class="table-div message-table search-table">
            <el-table
            :data="seachShowData"
            style="width: 100%"
            border
            empty-text="这里还没有消息"
            >
              <el-table-column prop="title" label="标题" width="550" :resizable="false">
              </el-table-column>
              <el-table-column prop="msgtype" label="通知类型" width="80" :resizable="false">
                <template>
                  <div class="msglist-item">
                    <div class="msglist-item-text">咨询回复</div>
                  </div>
                </template>
              </el-table-column>
              <el-table-column prop="createTime" label="时间" width="175" :resizable="false">
              </el-table-column>
              <el-table-column prop="status" label="是否已读" width="80" :resizable="false">
              </el-table-column>
            
            
              <el-table-column label="操作" :resizable="false">
                <template slot-scope="scope">
                  <div class="edit-div">
                    <div class="edit-text posi" @click="DetailClicked(scope.row)" type="primary" style="margin-left">详情</div>
                  </div>
                </template>
              </el-table-column>
            </el-table>
          </div>
          <!-- 页码 -->
          <div class="pagination-div">
            <!-- <el-pagination
              background
              layout="prev, pager, next"
              :hide-on-single-page="true"
              :page-count="pageCount"
              :current-page.sync="currentPage"
              @current-change="currentPageChanged"
            >
            </el-pagination> -->
            <Pagination
              :total="pageCount"
              :page.sync="currentPage"
              :limit.sync="pageSize"
              @pagination="getMessage"
            />
          </div>
          <div class="drawdiv">
            <el-drawer :title="'站内信'" :visible.sync="drawer" direction="rtl" :size=325>
              <div class="messagetitle-div">{{msgTitle}}</div>
              <div class="messagedetail-div">{{msgContent}}</div>
              <div class="message-box">
                <div class="message-read" @click="messageReadClicked()">我已阅读</div>
              </div>
              
            </el-drawer>
          </div>
         
          
        </div>
      </div>   
    </div>
  </div>
</template>

<script>
import { showMailToUserByPage } from '@/api/user/user'
import { showMailContent } from '@/api/user/user'
import { readedMailById,checkHaveNewMail } from '@/api/user/user'
import Pagination from "@/components/pagination.vue";
export default {
  name: "OrderSearch",
  components:{
    Pagination
  },
  data(){
    return {
      //是否显示提示
      tipShow: true,
      drawer:false,
      seachShowData: [],
      pageCount: 0,
      currentPage: 1,
      pageSize: 50,
      // 当前点击站内信的id
      msgid:0,

      //站内信详情信息
      msgTitle:"",
      msgContent:"",
      
    }
  },
  created(){
    this.getMessage();
    console.log("进入站内信页面");
  },
  methods:{
    // 获取站内信列表信息
    getMessage(){
      let upData = {
        pageNum: this.currentPage,
        pageSize: this.pageSize
      }
      showMailToUserByPage(upData).then(res => {
        if(res.data.code === 0) {
          this.seachShowData = res.data.data.list.list;
          this.pageCount = res.data.data.list.totalCount;
        }
          console.log(res, '站内信接口返回值');
      })
    },

    getMessageContent(){
      let upData = {
        id: this.msgid
      }
      showMailContent(upData).then(res => {
        if(res.data.code === 0) {
          this.msgTitle = res.data.data.info.title;
          this.msgContent = res.data.data.info.content;
        }
        console.log(res, "站内信详情");
        
      })
    },
    
    msgRead(){
      let upData = {
        id: this.msgid
      }
      readedMailById(upData).then(res => {
        if(res.data.code === 0) {
          this.$message.success(res.data.message);
          this.getMessage();
          // 更新站内信红点状态
          this.getNewMsg();
        }else{
          this.$message.success(res.data.message);
        }
        this.drawer = false;
        console.log(res, "站内信已读");
      })
    },
    //获取是否有新站内信
    getNewMsg(){
      checkHaveNewMail().then(res =>{
        // 是否存在未读消息
        let mesRedPoint = false;
        if(res.data.code === 0){
          if(res.data.data.count > 0){
            mesRedPoint=true;
          }else{
            mesRedPoint=false;
          }  

          this.$emit('updataStatus', mesRedPoint)
        }
      })
    },
    // 查看订单被点击
    DetailClicked(arg1){
      this.msgid = arg1.id;
      this.getMessageContent();
      this.drawer = true;
      console.log(arg1, "详情被点击");
    },
   
   
    // 切换页码
    currentPageChanged(arg){
      console.log("切换了页码",arg);
      //请求数据
      this.getMessage();
    },

    //我已阅读被点击
    messageReadClicked(){
      console.log("我已阅读被点击");
      this.msgRead();
    }
  },
}
</script>

<style>
/* table样式 */
.OrderSearch .search-table .el-table__row > td:nth-child(2) .cell,
.OrderSearch .search-table .el-table__row > td:nth-child(4) .cell{
  text-align: center;
}
/* 抽屉的样式 */
.drawer-div .el-drawer__header{
  margin-bottom: 0px;
  padding: 30px;
  border-bottom: 1px solid #E5DAEB;
  font-size: 16px;
  font-weight: 400;
  color: #1E2B4F;
}
.drawer-div [class*=" el-icon-"], 
.drawer-div [class^=el-icon-]{
  color: #6D5EFE;
}
</style>

<style lang="less" scoped>
.OrderSearch{
  width: 100%;
  .main{
  
    // 查询显示
    .order-search-show{
      display: flex;
      flex-direction: column;
      background: #FFFFFF;
      border-radius: 8px;
      padding: 24px 0px 16px 0px;

      // 搜索结果
      .search-result-show{
    
        padding: 0px 24px 0px 24px;
        // 表格内部样式
        .table-div{
          // 订单号item样式
          .msglist-item{
            .msglist-item-text{
              text-align: left;
            }
          }
          .msglist-item-mid{
            margin-top: 6px;
            margin-bottom: 6px;
          }
         
          // 操作
          .edit-div{
            .edit-text{
              height: 20px;
              font-size: 14px;
              font-weight: 400;
              color: #6D5EFE;
              line-height: 20px;
            }
           
          }
        }
        //站内信详情标题
          .drawdiv{
            .messagetitle-div{
            margin-left: 24px;
            font-size: 24px;
            font-weight: 600;
            color: #1E2B4F;
            line-height: 33px;
            word-break: break-all;
          }
          .messagedetail-div{
            padding: 24px;
            font-size: 14px;
            font-weight: 400;
            color: #1E2B4F;
            line-height: 30px;
          }
          .message-box{
            display: flex;
            justify-content: center;
          }
          .message-read{
            width: 100px;
            height: 36px;
            background: #6D5EFE;
            border-radius: 8px;
            font-size: 14px;
            font-weight: 400;
            color: #FFFFFF;
            line-height: 36px;
            text-align: center;
            margin-left: 20px;
            cursor: pointer;
          }
        }
      }
    }
  }
}
</style>